<div class="row justify-content-center mt-3 d-flex flex-lg-row-reverse">
    <div class="col-lg-6 col-12">
        <div class="row justify-content-center mt-4">
            <div class="col-8 blue_box p-4 text-center">
                <h3 class="heading">Uh-Oh!</h3>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-11 white_box p-4">
                <div class="row mt-4 justify-content-center">
                    <div class="col-10 text-center">
                        <h6>We are currently encountering an issue while processing your application. Please reach out to us on +91-9930133163. Thank you for your understanding.</h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-5 col-11">
        <app-assist-left></app-assist-left>
    </div>
</div>
<ngx-spinner type="ball-spin-clockwise" size="medium"></ngx-spinner>