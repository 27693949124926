<div *ngIf="isAutoPayLoading">
    <app-please-wait-nach></app-please-wait-nach>
</div>
<div *ngIf="!isAutoPayLoading">
    <div class="row justify-content-center mt-3 d-flex flex-lg-row-reverse">
        <div class="col-lg-6 col-12">
            <div class="row justify-content-center mt-4">
                <div class="col-8 blue_box p-4 text-center">
                    <h3 class="heading">Setup E-Nach</h3>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-11 white_box p-4">
                    <div class="row justify-content-center">
                        <div class="col-11">
                            <p class="text-center mb-4" style="font-size: 1rem;">Link your Debit Card or Bank Account to
                                Repay EMI automatically on due dates by setting up - E-Nach </p>
                            <form [formGroup]="nachForm" (ngSubmit)="onSubmit()" novalidate>

                                <div class="row mt-2 justify-content-center">
                                    <div class="col-sm-5 col-12 mt-1">
                                        <div class="form-group">
                                            <label for="Traveltype " class="control-label">Account Type</label>
                                            <select class="form-select" id="accountType" formControlName="accountType"
                                                required="">
                                                <option disabled="" selected="" value="">Choose Account Type</option>
                                                <option value="Savings">Savings</option>
                                                <option value="Current">Current</option>
                                            </select>
                                            <div class="error"
                                                *ngIf="m.accountType.invalid && (m.accountType.dirty || m.accountType.touched)">
                                                <div *ngIf="m.accountType.errors?.required">Please select Account Type.
                                                </div>
                                            </div>
                                            <!-- <fieldset class="border" style="margin-top: 3px;border-radius: 3px;padding-left: .3rem;">
                                            <legend class="w-auto" style="font-size: 14px; font-weight: 100; border-bottom: none; margin-bottom: -6px; padding: 0 5px 0 5px;">Account Type <span class="required-asterisk" style="color:  red;">*</span></legend>
                                            <select class="form-control no-outline" id="accountType" formControlName="accountType" required="">
                                            <option disabled="" selected="" value="">Choose Account Type</option>
                                            <option value="Savings">Savings</option>
                                            <option value="Current">Current</option>
                                            </select>
                                        </fieldset>
                                        <div class="error" *ngIf="m.accountType.invalid && (m.accountType.dirty || m.accountType.touched)">
                                            <div *ngIf="m.accountType.errors?.required">Please select Account Type.</div>
                                        </div> -->
                                        </div>
                                    </div>
                                    <div class="col-sm-5 col-12 mt-1">
                                        <div class="form-group">
                                            <label for="Mobilenumber" class="control-label">IFSC Code</label>
                                            <input placeholder="IFSC Code" #ifscCode type="text"
                                                formControlName="bankId" class="form-control"
                                                (focusout)="getBankName($event); upperCase($event)">
                                            <div *ngIf="m.bankId.touched && m.bankId.invalid" class="error">
                                                <div *ngIf="m.bankId.errors?.required">IFSC Code is required</div>
                                                <div *ngIf="m.bankId.errors?.pattern">IFSC Code is invalid</div>
                                            </div>
                                        </div>
                                        <!-- <div class="form-group">
                                        <fieldset class="border" style="margin-top: 3px;border-radius: 3px;padding-left: .3rem;">
                                            <legend class="w-auto" style="font-size: 14px;font-weight: 100;border-bottom: none;margin-bottom: -6px;padding: 0 5px 0 5px;">Enter IFSC Code <span class="required-asterisk" style="color:  red;">*</span></legend>
                                            <input placeholder="IFSC Code" #ifscCode type="text" formControlName="bankId" class="form-control no-outline" (focusout)="getBankName($event); upperCase($event)">
                                        </fieldset>
                                        <div *ngIf="m.bankId.touched && m.bankId.invalid" class="error">
                                            <div *ngIf="m.bankId.errors?.required">IFSC Code is required</div>
                                            <div *ngIf="m.bankId.errors?.pattern">IFSC Code is invalid</div>
                                        </div>
                                    </div> -->
                                    </div>
                                </div>


                                <div class="row justify-content-center">
                                    <div class="col-sm-10 col-12">
                                        <div class="form-group">
                                            <label for="Mobilenumber" class="control-label">Account Number</label>
                                            <input type="text" placeholder="Enter Account Number"
                                                formControlName="accountNumber" class="form-control" maxlength="18"
                                                minlength="1">
                                            <div *ngIf="m.accountNumber.touched && m.accountNumber.invalid"
                                                class="error">
                                                <div *ngIf="m.accountNumber.errors?.required">Account number is required
                                                </div>
                                                <div *ngIf="m.accountNumber.errors?.pattern">Invalid account number
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div class="form-group">
                                        <fieldset class="border" style="margin-top: 3px;border-radius: 3px;padding-left: 0.5rem;">
                                            <legend class="w-auto" style="font-size: 14px;font-weight: 100;border-bottom: none;margin-bottom: -6px;padding: 0 5px 0 5px;">Enter Account Number <span class="required-asterisk" style="color: red;">*</span></legend>
                                            <input type="text" placeholder="Account Number" formControlName="accountNumber" class="form-control no-outline" maxlength="18" minlength="1">
                                        </fieldset>
                                        <div *ngIf="m.accountNumber.touched && m.accountNumber.invalid" class="error">
                                            <div *ngIf="m.accountNumber.errors?.required">Account number is required</div>
                                            <div *ngIf="m.accountNumber.errors?.pattern">Invalid account number</div>
                                        </div>
                                   </div> -->
                                    </div>
                                </div>


                                <!-- <div class="row mt-4 justify-content-center">
                                <div class="col-sm-5 col-12">
                                    <div class="form-group">
                                        <label>IFSC Code</label>
                                        <input placeholder="IFSC Code" #ifscCode type="text" formControlName="bankId" class="form-control mb-2" (focusout)="getBankName($event); upperCase($event)">
                                        <div *ngIf="m.bankId.touched && m.bankId.invalid" class="error">
                                            <div *ngIf="m.bankId.errors?.required">IFSC Code is required</div>
                                            <div *ngIf="m.bankId.errors?.pattern">IFSC Code is invalid</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-5 col-12">
                                    <div class="form-group">
                                        <label>Account Number</label>
                                        <input type="text" placeholder="Account Number" formControlName="accountNumber" class="form-control mb-2" maxlength="18" minlength="1">
                                        <div *ngIf="m.accountNumber.touched && m.accountNumber.invalid" class="error">
                                            <div *ngIf="m.accountNumber.errors?.required">Account
                                                number is required</div>
                                            <div *ngIf="m.accountNumber.errors?.pattern">Invalid account
                                                number</div>
                                        </div>
                                    </div>
                                </div>
                            </div> -->

                                <div class="row justify-content-center mb-4" *ngIf="bankName!=''">
                                    <div class="col-10 bank_name">
                                        {{bankName}}
                                    </div>
                                </div>
                                <div class="row justify-content-center">
                                    <div class="col-12 consent-box">
                                        <div class="boxes">
                                            <input type="checkbox" style="margin-right: 1rem;" id="box1"
                                                (change)="checkValue($event)" [(ngModel)]="conditionAccept"
                                                formControlName="chkAgree">
                                            <label for="box1"> I agree to terms & conditions of the agreement</label>
                                        </div>
                                        <!-- <p class="mb-1 text-left consent-p">I authorize Mihuru to cancel the flight
                                            on my behalf in the
                                            event I fail to pay the EMIs on time.</p> -->
                                    </div>
                                </div>
                                <div class="row justify-content-center text-center buttonDiv">
                                    <div class="col-6">
                                        <button class="btn btn-warning mt-3" [disabled]="!nachForm.valid"
                                            type="submit">Proceed</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-5 col-11">
            <app-assist-left></app-assist-left>
        </div>
    </div>
    <app-nach-pop [timerSub]="paymentStatusSubscription"></app-nach-pop>
</div>
<ngx-spinner type="ball-spin-clockwise" size="medium"></ngx-spinner>