<app-assist-emi-table></app-assist-emi-table>
<div class="d-none">
    <div class="user_details mt-4">
        <div class="row">
            <div class="col-11 mb-4">
                <p><span>Hi Prajakta! </span>You are just a step away from financing your trip! Please confirm the following details to proceed.</p>
            </div>
        </div>
        <div class="row justify-content-around content_div">
            <div class="col-5">
                <p><img src="../../assets/images/assist-contact.png" /> 9987287606</p>
            </div>
            <div class="col-5">
                <p><img src="../../assets/images/assist-email.png" /> youremailID&#64;gmail.com</p>
            </div>
        </div>
    </div>
</div>
<div class="d-none">
    <div class="row justify-content-center mt-4">
        <div class="col-8 blue_box p-4 text-center">
            <h3>Identity Proof</h3>
        </div>
    </div>
    <div class="row justify-content-center">
        <div class="col-11 white_box p-4">
            <table class="table table-hover table-sm table-borderless w-75 mb-4">
                <tbody>
                    <tr>
                        <th scope="row">EMI</th>
                        <td>₹ 1,389
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">
                            Loan amount</th>
                        <td>
                            ₹ 8,500</td>
                    </tr>
                    <tr>
                        <th scope="row">
                            Total EMI</th>
                        <td>
                            8</td>
                    </tr>
                    <tr>
                        <th scope="row">
                            Downpayment</th>
                        <td>
                            ₹ 1,700</td>
                    </tr>
                    <tr>
                        <th scope="row">
                            Processing fees</th>
                        <td>
                            ₹ 170</td>
                    </tr>
                </tbody>
            </table>
            <p class="note"><img src="../../assets/images/assist-icon-info.png" /> EMI due on 5th of every month</p>
        </div>
    </div>
</div>