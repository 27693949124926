import { CommonModule, DatePipe } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
// import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { UrlSerializer } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RoundProgressModule } from 'angular-svg-round-progressbar';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { CountdownModule } from 'ngx-countdown';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthInterceptorService } from './core/interceptor/auth-interceptor.service';
import { LowerCaseUrlSerializer } from './core/services/lowercaseurlserializer';
import { FooterComponent } from './layout/footer/footer.component';
import { MenuComponent } from './layout/menu/menu.component';
import { AssistHeaderBannerComponent } from './modules/assist-journey/assist-header-banner/assist-header-banner.component';
import { AssistJourneyModule } from './modules/assist-journey/assist-journey.module';
import { BookingDoneComponent } from './modules/assist-journey/booking-done/booking-done.component';
import { DocumentUploadComponent } from './modules/assist-journey/document-upload/document-upload.component';

@NgModule({ declarations: [
        AppComponent,
        MenuComponent,
        FooterComponent,
        DocumentUploadComponent,
        BookingDoneComponent,
        AssistHeaderBannerComponent,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [AppComponent], 
    imports: [RoundProgressModule,
        CommonModule,
        BrowserModule,
        RouterModule,
        // NgbModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        FontAwesomeModule,
        FormsModule,
        ReactiveFormsModule,
        CountdownModule,
        AssistJourneyModule,
        NgxSpinnerModule,
        NgCircleProgressModule.forRoot({
            // set defaults here
            radius: 100,
            outerStrokeWidth: 16,
            innerStrokeWidth: 8,
            outerStrokeColor: '#78C000',
            innerStrokeColor: '#C7E596',
            animationDuration: 300,
        }),
        CollapseModule.forRoot(),
        AssistJourneyModule
    ], 
    providers: [
        DatePipe,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptorService,
            multi: true,
        },
        {
            provide: UrlSerializer,
            useClass: LowerCaseUrlSerializer,
        },
        provideHttpClient(withInterceptorsFromDi()),
    ] })

export class AppModule { }
