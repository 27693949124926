<div class="row justify-content-center mt-3 d-flex flex-lg-row-reverse m-md-css">
    <div class="col-lg-6 col-12">
        <app-assist-personal-details [FirstName]="FirstName" [Mobile]="Mobile" [Email]="Email" [PAN]="PAN" [DOB]="DOB"
            [Address]="Address" [DocsRequired]="DocsRequired"
            [btnProceedText]="btnProceed"></app-assist-personal-details>
    </div>
    <div class="col-lg-5 col-12" *ngIf="!hideEMI">
        <app-assist-emi-table [Downpayment]="Downpayment" [EmiAmount]="EmiAmount" [Loanamount]="Loanamount"
            [NumberOfTotalEMI]="NumberOfTotalEMI" [ProcessingFee]="ProcessingFee" [PayToday]="PayToday" [FirstEMIDueDate]="FirstEMIDueDate">
        </app-assist-emi-table>
    </div>
</div>
<ngx-spinner type="ball-spin-clockwise" size="medium"></ngx-spinner>