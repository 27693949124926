import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, interval, Subscription } from 'rxjs';
import { MemoryStorageService } from 'src/app/core/services/memory-storage.service';

@Injectable({
  providedIn: 'root'
})
export class SessionTimerService {
  private remainingTimeSubject = new BehaviorSubject<number>(900);
  remainingTime$ = this.remainingTimeSubject.asObservable();
  private timerSubscription: Subscription | null = null;
  private sessionTimerShow =new BehaviorSubject<boolean>(true);
  ShowTimerObservable =this.sessionTimerShow.asObservable();

  constructor(private router: Router) {
    // if (this.endTime) {
    //   this.startTimer();
    // }
  }

  SetShowTimer(val: boolean)
  {
    this.sessionTimerShow.next(val);
  }

  private getInitialTime(): number {
    return (60*15); // Default to 15 minutes
  }
  private getEndTime(): number | null {
    const endTime = MemoryStorageService.getItem('timerEndTime');
    return endTime ? +endTime : null;
  }
  private getRemainingTime(): number {
    const endTime = this.getEndTime();
    if (endTime) {
      const now = Date.now();
      const remainingTime = Math.max(Math.floor((endTime - now) / 1000), 0);
      if (remainingTime === 0) {
        this.router.navigateByUrl('/assist-journey/session-expired');
      }
      return remainingTime;
    }
    this.router.navigateByUrl('/assist-journey/session-expired');
    //return this.getInitialTime();
  }

  startTimer() {
    this.timerSubscription?.unsubscribe(); 
    this.timerSubscription = interval(1000).subscribe(() => {
      this.updateRemainingTime();
    });
  }
  private updateRemainingTime() {
    let currentTime = this.remainingTimeSubject.value;
    if (currentTime > 0) {
      currentTime--;
      this.remainingTimeSubject.next(currentTime);
      this.storeEndTime();
    } else {
      this.stopTimer();
      this.router.navigateByUrl('/assist-journey/session-expired');
    }
  }
  private storeEndTime() {
    const now = Date.now();
    const endTime = now + this.remainingTimeSubject.value * 1000;
    MemoryStorageService.setItem('timerEndTime', endTime.toString());
  }
  stopTimer() {
    this.timerSubscription?.unsubscribe();
    //this.remainingTimeSubject.complete();
    MemoryStorageService.removeItem('timerEndTime');
  }
  restartTimer() {
    const initialTime = this.getInitialTime();
    this.remainingTimeSubject.next(initialTime);
    this.storeEndTime();
    this.startTimer();
  }

  resumeTimer(){
    const initialTime = this.getRemainingTime();
    if(!isNaN(initialTime) || initialTime !== null ){
      this.remainingTimeSubject.next(initialTime);
      this.storeEndTime();
      this.startTimer();
      this.SetShowTimer(true);
    }
    else 
    {
      this.router.navigateByUrl('/assist-journey/session-expired');
    }
  }
}











