import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MemoryStorageService } from 'src/app/core/services/memory-storage.service';
import { SubscribeService } from 'src/app/core/services/subscribe.service';

declare var Moengage; 

declare var hbspt:any;
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public ShowDivOnSuccess: boolean = false;
  public defaultHeaders: Headers = new Headers();
  public user: any;
  public data;
  public sucessmsg: boolean = false;
  public errormsg: string = "";
  public errorshow: boolean = false;
  shwHubspot:boolean = false;
  emailId:string = "";
  errorConflictshow:boolean = false;
  constructor(
    private subscribeService: SubscribeService,
    private router : Router
  ) { }

  ngOnInit(): void {
    this.user = JSON.parse(MemoryStorageService.getItem('user'))

  }

  _keyPress(evt: any) {
    this.errorshow = false;
    this.sucessmsg = false;
  }

  onSubmitForm(event, value) {
  //  console.log(value, "email value---");
    if (value == '') {
      this.errorshow = true;
      return;
    } else {
      this.errorshow = false;
      if (this.user == '') {
        this.data = {
          company: "",
          email: value,
          firstname: "",
          lastname: "",
          phone: "",
          website: ""

        }
      } else {
        this.data = {
          company: "",
          email: value,
          firstname: this.user.email,
          lastname: "",
          phone: this.user.mobileNumber,
          website: ""
        }
      }

      this.subscribeService.newsLetterSubscribe(this.data)
        .subscribe(res => {
          console.log(res, "respone---");
          this.sucessmsg = true;
          this.errorshow = false;
          this.errorConflictshow = false;
          this.emailId = "";
          //Moengage.track_event("App Content Refreshed", {"Footer": "Subscribe to Newsletter", "Email": this.data.value, "moe_non_interactive": 1})
          Moengage.track_event("Footer - Subscribe to Newsletter", {"Footer": "Subscribe to Newsletter", "Email": this.data.value, "moe_non_interactive": 1})
        }, error => {
          console.log(error.error, "errrors-----");
          if(error.error.indexOf("Conflict") > 0){
            this.errorConflictshow = true;
            this.sucessmsg = false;
            this.errorshow = false;
          }
          else{
            this.errorshow = true;
            this.errorConflictshow = false;
            this.sucessmsg = false;
            this.errormsg = error.error.errors;
          }
        });
      value.value == '';
      
    }
  }

  applyEducation(){
    this.shwHubspot = true;
    hbspt.forms.create({
      region: "na1",
      portalId: "4664708",
      formId: "db8bc445-2b33-46b2-b139-221fb09a8e5a",
      target: "#hubspotForm"
    });
    //Moengage.track_event("App Content Refreshed", {"Footer": "Education Businesses Visited", "moe_non_interactive": 1})
    Moengage.track_event("Footer - Education Businesses Visited", {"Footer": "Education Businesses Visited", "moe_non_interactive": 1})
  }

  closeModal(){
    this.shwHubspot = false;
  }

  moEngageTrackFooter(footerEle : string){
    if(footerEle == "aboutus"){
      //Moengage.track_event("App Content Refreshed", {"Footer": "Who Are We", "moe_non_interactive": 1})
      Moengage.track_event("Footer - Who Are We", {"Footer": "Who Are We", "moe_non_interactive": 1})
      this.router.navigateByUrl('/about-us');
    }else if(footerEle == "FAQ"){
      //Moengage.track_event("App Content Refreshed", {"Footer": "FAQ Visited", "moe_non_interactive": 1})
      Moengage.track_event("Footer - FAQ Visited", {"Footer": "FAQ Visited", "moe_non_interactive": 1})
      //this.router.navigateByUrl('https://help.mihuru.com/');
      window.open("https://help.mihuru.com/", "_blank");
    }else if(footerEle == "TermsOfUse"){
      //Moengage.track_event("App Content Refreshed", {"Footer": "Terms of use Visited", "moe_non_interactive": 1})
      Moengage.track_event("Footer - Terms of use Visited", {"Footer": "Terms of use Visited", "moe_non_interactive": 1})
      //this.router.navigateByUrl("['/privacy','terms']");
      window.open("https://www.mihuru.com/privacy/terms", "_blank");
    }else if(footerEle == "PrivacyPolicy"){
      //Moengage.track_event("App Content Refreshed", {"Footer": "Privacy Policy Visited", "moe_non_interactive": 1})
      Moengage.track_event("Footer - Privacy Policy Visited", {"Footer": "Privacy Policy Visited", "moe_non_interactive": 1})
      //this.router.navigateByUrl("['/privacy','privacy']");
      window.open("https://www.mihuru.com/privacy/privacy", "_blank");
    }else if(footerEle == "ContactUs"){
      //Moengage.track_event("App Content Refreshed", {"Footer": "Contact Us Visited", "moe_non_interactive": 1})
      Moengage.track_event("Footer - Contact Us Visited", {"Footer": "Contact Us Visited", "moe_non_interactive": 1})
      this.router.navigateByUrl('/contact-us');
    }else if(footerEle == "Discover"){
      //Moengage.track_event("App Content Refreshed", {"Footer": "Discover Visited", "moe_non_interactive": 1})
      Moengage.track_event("Footer - Discover Visited", {"Footer": "Discover Visited", "moe_non_interactive": 1})
      this.router.navigateByUrl('/Discover');
    }else if(footerEle == "Blog"){
      //Moengage.track_event("App Content Refreshed", {"Footer": "Blog Visited", "moe_non_interactive": 1})
      Moengage.track_event("Footer - Blog Visited", {"Footer": "Blog Visited", "moe_non_interactive": 1})
      //this.router.navigateByUrl('https://blog.mihuru.com/');
      window.open("https://blog.mihuru.com/", "_blank");
    }else if(footerEle == "TravelBusiness"){
      //Moengage.track_event("App Content Refreshed", {"Footer": "Travel Businesses Visited", "moe_non_interactive": 1})
      Moengage.track_event("Footer - Travel Businesses Visited", {"Footer": "Travel Businesses Visited", "moe_non_interactive": 1})
      this.router.navigateByUrl('/travel-agent');
    }
    
  }
}
