import { Component, OnInit } from '@angular/core';
import { SessionTimerService } from '../../../core/services/session-timer.service';

@Component({
  selector: 'app-process-offline',
  templateUrl: './process-offline.component.html',
  styleUrls: ['./process-offline.component.scss']
})
export class ProcessOfflineComponent implements OnInit {

  constructor(private sessionTimerService :SessionTimerService) { }

  ngOnInit(): void {
    this.sessionTimerService.SetShowTimer(false);
    this.sessionTimerService.stopTimer();
  }

}
