import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import swal from 'sweetalert2';
import { CreditRuleRequest, CreditRuleResponse, CreditRuleResponseData } from '../models/CreditRuleRequest';

@Injectable({
  providedIn: 'root'
})
export class CreditRuleService {
  baseUrl: string = '';

  constructor(private httpClient: HttpClient) {
    this.baseUrl = environment.apiUrl;
   }

  UserCreditReport(creditRuleRequest: CreditRuleRequest): Observable<CreditRuleResponse> {  
    
    var listOfCRD: Array<CreditRuleResponseData> = [{
      "rateOfInterest": 2.0,
      "monthlyIncome": 50000.0,
      "sanctionedlimit": 25000.0,
      "creditScore": 583,
      "creditReportMasterValue": "Application.Services.RAGSteps",
      "creditReportMasterId": 0,
      "creditInPrincipalLimitMasterId": 0,
      "creditInPrincipalLimitMasterValue": "",
      "productName": "SplitFare",
      "userRAGDecision": "GREEN"
    }];
    var crr: CreditRuleResponse = {
      "error": {
        "errorCode": null,
        "errorDesc": null
      },
      "data": listOfCRD
    }
    //return of(crr);

    return this.httpClient.post(this.baseUrl + 'UserCreditRules/usercreditruleengineI', creditRuleRequest).pipe(
      map((response: CreditRuleResponse) => {
        const data = response;
            return data;
        }, error => {
          console.log(error);
          swal.fire(error.errors);
          return error;
      })
    );

  }

  GetCreditReportPDF(){
    return this.httpClient.get(this.baseUrl + 'UserCreditRules/GetCreditReportPDF');
  }

}
