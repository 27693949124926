import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import swal from 'sweetalert2';
import { environment } from '../../../../environments/environment';
import { SessionTimerService } from '../../../core/services/session-timer.service';

@Component({
  selector: 'app-address-proof',
  templateUrl: './address-proof.component.html',
  styleUrls: ['./address-proof.component.scss'],
})
export class AddressProofComponent implements OnInit {
  baseUrl!: string;
  constructor(private router: Router, private sessionTimerService :SessionTimerService, private spinner: NgxSpinnerService) {
    this.baseUrl = environment.apiUrl + 'assistmodule/v1/createUserProfile';
  }

  ngOnInit(): void {
    this.spinner.hide();
    //this.sessionTimerService.resumeTimer();
  }
  aadhaarOTPGenerated: boolean = false;
  displayVerification: boolean = false;
  ProceedToDocUpload(response: any) {
    this.spinner.show();
    if (response['error'] == null) {
      swal.fire(response['data']['response_msg']);
      //success
      this.displayVerification = true;
      this.aadhaarOTPGenerated = true;
    } else {
      swal.fire(response['error']['response_msg'] + 'Please proceed with aadhaar number');
      //failure (2nd time k baad)
      this.displayVerification = true;
      this.aadhaarOTPGenerated = false;
    }
    this.spinner.hide();
  }
}
