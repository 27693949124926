<div class="row">
    <div class="travel w-100">
        <div class="row align-items-center h-75 justify-content-center">
            <div class="col col-sm-11 col-md-11 offset-md-1 h-50 col-11">
                <h4 class="txt_gray the">The</h4>
                <h1 class="txt_gray world">world</h1>
                <h4 class="txt_gray is">is</h4>
                <h1 class="txt_orange yours">yours</h1>
                <h4 class="txt_gray go_see">Go see it with Mihuru</h4>
            </div>
        </div>
    </div>
</div>