<div class="row justify-content-center mt-3 d-flex flex-lg-row-reverse">
    <div class="col-lg-6 col-12">
        <app-upload-and-preview *ngIf="!displayVerification" [apiUrl]="baseUrl" [requestCode]="'5011'"
            [docType]="'address proof'" [subDocType]="'aadhaar'" (apiResponse)="ProceedToDocUpload($event)"
            [maxFileCount]="2">
            <h3 class="heading">Address proof</h3>
            <p class="heading1">Please provide your AADHAAR Card as address proof</p>
            <p class="uploaddocfooter text-success mb-0">Please upload the documents only in JPEG, PNG or PDF format</p>
        </app-upload-and-preview>
        <app-aadhaar-verification [aadhaarOTPGenerated]="aadhaarOTPGenerated" [apiUrl]="baseUrl"
            *ngIf="displayVerification"></app-aadhaar-verification>
    </div>
    <div class="col-lg-5 col-11">
        <app-assist-left></app-assist-left>
    </div>
</div>
<ngx-spinner type="ball-spin-clockwise" size="medium"></ngx-spinner>