<div class="border-box">
    <div class="row justify-content-center mt-4">
        <div class="col-8 blue_box p-4 text-center">
            <h3 class="heading" *ngIf="!aadhaarOTPGenerated">Aadhaar Verification</h3>
            <h3 class="heading" *ngIf="aadhaarOTPGenerated">Submit Aadhaar OTP</h3>
        </div>
    </div>
    <form [formGroup]="myForm" (ngSubmit)="onSubmit()" novalidate *ngIf="!aadhaarOTPGenerated">
        <div class="row justify-content-center">
            <div class="col-11 white_box p-4">
                <div class="row">
                    <div class="col-12">
                        <div class="row justify-content-center">
                            <div class="col-10">
                                <div class="form-group">
                                    <label class="control-label">Enter 12 digit aadhaar number</label>
                                    <input type="text" formControlName="documentNumber" class="form-control" maxlength="12" minlength="12">
                                    <div *ngIf="m.documentNumber.touched && m.documentNumber.invalid" class="error">
                                        <div *ngIf="m.documentNumber.errors?.required">Please enter aadhaar number</div>
                                        <div *ngIf="m.documentNumber.errors?.pattern">Aadhaar no should be of 12 digits</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row justify-content-center text-center buttonDiv">
                            <div class="col-12">
                                <button class="btn btn-warning" [disabled]="!myForm.valid" type="submit">Generate
                                    OTP</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <form [formGroup]="myForm1" (ngSubmit)="onSubmitOtp()" novalidate *ngIf="aadhaarOTPGenerated">
        <div class="row justify-content-center">
            <div class="col-11 white_box p-4">
                <div class="row justify-content-center">
                    <div class="col-10">
                        <div class="form-group">
                            <label class="control-label">Enter otp received on aadhaar linked mobile</label>
                            <input type="text" formControlName="aadhaarOtp" class="form-control" maxlength="6" minlength="6">
                            <div class="timer-container">
                                <button *ngIf="!resetButtonEnable" class="btn btn-link btn-response" (click)="onResendOTP()">
                                    <i class="fa fa-paper-plane" aria-hidden="true" style="color: #044b5f;font-size: 0.9em;"></i>
                                    <svg height="20" width="60" style="font-weight: unset;">
                                        <text x="5" y="13" fill="blue" font-size="0.9em" style="font-weight: 500;">Resend</text>
                                   </svg>
                                </button>
                            </div>
                            <div *ngIf="s.aadhaarOtp.touched && s.aadhaarOtp.invalid" class="error">
                                <div *ngIf="s.aadhaarOtp.errors?.required">Please enter otp</div>
                                <div *ngIf="s.aadhaarOtp.errors?.pattern">Otp is not 6 digit</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center mt-2 text-center buttonDiv">
                    <!-- <div class="col-12 mb-3">
                        <button class="btn interLink" [disabled]="!resetButtonEnable" type="button" (click)="onResendOTP()">Resend OTP</button>
                    </div> -->
                    <div class="col-12">
                        <button class="btn btn-warning" [disabled]="!myForm1.valid" type="submit">Submit OTP</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
<ngx-spinner type="ball-spin-clockwise" size="medium"></ngx-spinner>