<nav class="navbar fixed-top navbar-expand-lg" *ngIf="!showOnMobile" [ngClass]="{'bg-white':navWhiteBg === true}">
    <div class="row w-100 m-0">
        <div class="col-6">
            <div class="navbar-brand">
                <img alt="Mihuru" src="../../../assets/images/logoitrevele-black.png">
            </div>
            <div class="navbar-brand" *ngIf="isParterLogo">
                <c>in partnership with</c><img alt="Mihuru" [src]="partnerLogourl">
            </div>
        </div>
        <div class="col-6 text-right mt-2">
            <app-session-timeout> </app-session-timeout>
        </div>
    </div>
</nav>
<nav #menu1 class="navbar navbar-expand-sm" *ngIf="showOnMobile && !scrollOnMobile">
    <div class="row m-0">
        <div class="col-10" style="padding-left: .2rem;">
            <div class="navbar-brand" style="margin-right: 0.3rem;">
                <img alt="Mihuru" src="../../../assets/images/logoitrevele-black.png">
            </div>
            <div class="navbar-brand" *ngIf="isParterLogo" style="margin: 0;">
                <c>in partnership with</c><img alt="Mihuru" [src]="partnerLogourl">
            </div>
        </div>
        <div class="col-2 text-right mt-2" style="padding: 0;">
            <app-session-timeout> </app-session-timeout>
        </div>
    </div>
</nav>