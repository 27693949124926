import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { SessionTimerService } from '../../../core/services/session-timer.service';

@Component({
  selector: 'app-assist-underwrite',
  templateUrl: './assist-underwrite.component.html',
  styleUrls: ['./assist-underwrite.component.scss']
})
export class AssistUnderwriteComponent implements OnInit {

  constructor(private sessionTimerService :SessionTimerService, private spinner: NgxSpinnerService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.spinner.hide();
    this.sessionTimerService.SetShowTimer(false);
    this.sessionTimerService.stopTimer();

    this.route.queryParamMap.subscribe(params => {
      var redirect = params.get('redirect');
      if(redirect && redirect !== 'undefined'){
        window.location.replace(redirect);
      }
    });
  }

}
