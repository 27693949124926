import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'encryptPhoneEmail'
})
export class EncryptPhoneEmailPipe implements PipeTransform {

  transform(value: string): string {
    if(value.indexOf("@") > -1){
      let email  = value.substring(0,1).concat("*****",value.substring(value.indexOf("@")-2))
      return email
    }
    else{
      let mobileNo = value.substring(0,2).concat("******",value.slice(-2))
      return mobileNo
    }
  }

}
