<footer class="page-footer darkFooter footer-style" id="footerStyle">
  <div class="container-fluid">


   

    <div class="row justify-content-center">
      <div class="col-12">
        <nav class="nav justify-content-center foo-links">
          <a href="https://www.mihuru.com/privacy/privacy" target="_blank" class="nav-link" style="padding: 2rem 1rem;">Privacy policy</a>
          <a href="https://www.mihuru.com/privacy/terms" target="_blank" class="nav-link" style="padding: 2rem 1rem;">Terms</a>
          <div style="display: flex;">
            <p style="text-decoration: none !important;font-family: Roboto, sans-serif;font-size: 0.8rem;line-height: 160%;font-weight: 400;text-decoration: none !important;border-bottom: 0px;color: #eaf6fc;padding: 2rem 1rem;">Our Lending Partner</p>
            <img src="../../../assets/logos/monedo.png" alt="Monedo Logo" style="height: 5em;">
          </div>
        </nav>
        <!-- <nav class="nav justify-content-center foo-links">
          <a class="nav-link" href="https://www.mihuru.com/privacy/privacy" target="_blank">Privacy policy</a>
          <a class="nav-link" href="https://www.mihuru.com/privacy/terms" target="_blank">Terms</a>
          <div>
            <img src="../../../assets/logos/monedo.png" alt="Monedo Logo"/>
          </div>
        </nav> -->
      </div>
    </div>
  </div>
</footer>
<div *ngIf="shwHubspot" class="modal" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Be Our EdTech Partner</h5>
        <button type="button" class="close" (click)="closeModal()" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-sm-12">
            <div id="hubspotForm">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>