import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SessionTimerService } from '../../../core/services/session-timer.service';
@Component({
  selector: 'app-session-timeout',
  templateUrl: './sessiontimeout.component.html',
  styleUrls: ['./sessiontimeout.component.scss'],
})
export class SessiontimeoutComponent implements OnInit {
  remainingTime: number = 0;
  formattedTime: string = '';
  ShowTimer: boolean = false;
  constructor(private timerService: SessionTimerService, private router: Router) { }
  ngOnInit(): void {
    this.timerService.remainingTime$.subscribe(time => {
      this.remainingTime = time;
      this.formattedTime = this.formatTime(time);
    });

    this.timerService.ShowTimerObservable.subscribe(val => 
      {
        this.ShowTimer = val;
      }
    );
    
  }
  private formatTime(seconds: number): string {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${this.pad(minutes)}:${this.pad(secs)}`;
  }
  private pad(value: number): string {
    return value < 10 ? '0' + value : value.toString();
  }
}









