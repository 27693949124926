import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { FileItem, FileLikeObject, FileUploader, FileUploaderOptions } from 'ng2-file-upload';
import { NgxSpinnerService } from 'ngx-spinner';
import { AccountService } from 'src/app/core/services/account.service';
import { MemoryStorageService } from 'src/app/core/services/memory-storage.service';
import swal from 'sweetalert2';
import { SessionTimerService } from '../../../core/services/session-timer.service';
import { lastValueFrom } from 'rxjs';
import { animate, style, transition, trigger } from '@angular/animations';

const toBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

enum ProfessionType {
  Salaried = 'Salaried',
  SelfEmployed = 'Self Employed',
}

enum CompanyType {
  Proprietorship = 'Proprietorship',
  Partnership = 'Partnership',
  LimitedLiabilityPartnership = 'Limited Liability Partnership',
  PrivateLimitedCompany = 'Private Limited Company',
}

enum BusinessDocument {
  UdyogAadhaar = 'Udyog Aadhaar',
  ITR = 'ITR',
  GST = 'GST',
  ShopEstablishmentCertificate = 'Shop and Establishment Certificate', // Removed
  GumastaLicense = 'Gumasta license', // Removed
}

export interface DocumentFile {
  professionIdtype: string,
  file: string,
  type: string
}

@Component({
  selector: 'app-professional-details',
  templateUrl: './professional-details.component.html',
  styleUrls: ['./professional-details.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }), 
        animate('500ms 0s ease-in', style({ opacity: 1 })) 
      ]),
      transition(':leave', [
        animate('500ms 0s ease-out', style({ opacity: 0 })) 
      ])
    ])
  ]
})
export class ProfessionalDetailsComponent implements OnInit {
  @ViewChild('businessdoc') businessdoc: ElementRef;
  @ViewChild('incomedoc') incomedoc: ElementRef;

  public professionTypes = [ProfessionType.Salaried, ProfessionType.SelfEmployed];
  public companyTypes = [CompanyType.Proprietorship, CompanyType.Partnership, CompanyType.LimitedLiabilityPartnership, CompanyType.PrivateLimitedCompany];
  public businessDocuments = [BusinessDocument.ITR, BusinessDocument.GST, BusinessDocument.UdyogAadhaar];
  
  public model: FormGroup;
  // public image!: any;
  public maxFileCount: number = 3;
  public hasBaseDropZoneOver: boolean;
  public pan: string = '';
  public bankStatementRequired: string = '';
  public isBankStatementUpload: boolean = false;
  public gstInactive: boolean = false;
  public showITR: boolean = false;
  public isMoved = false;

  filesData: DocumentFile[] = [
    { professionIdtype: '', file: '', type: '' }, 
    { professionIdtype: '', file: '', type: '' }
  ]

  uploaderOptions: FileUploaderOptions = {
    url: 'https://mihuru.com',
    queueLimit: this.maxFileCount,
    disableMultipart: true,
    formatDataFunctionIsAsync: true,
    formatDataFunction: async (item: FileItem) => {
      if (item.file.type === 'application/pdf' || item.file.type.startsWith('image/')) {
        return {
          name: item.file.name,
          length: item.file.size,
          contentType: item.file.type,
          date: new Date(),
        };
      } 
    },
    filters: [
      {
        name: 'imageAndPdfFilter',
        fn: (item: FileLikeObject) => {
          return item.type === 'application/pdf' || item.type.startsWith('image/');
        }
      }
    ]
  };

  uploader = new FileUploader(this.uploaderOptions);
  selectedFile: any = null;
  image: string | null = null;

  constructor(private fb: FormBuilder,
              private accountService: AccountService,
              private sanitizer: DomSanitizer,
              private router: Router,
              private sessionTimerService: SessionTimerService,
              private spinner: NgxSpinnerService) {
    this.model = this.fb.group({
      professionType: ['', Validators.required],
      companyName: [''],
      companyType: [''],
      businessDocument: [''],
      gst: ['', [Validators.pattern('^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$')]],
      skipGst: [false]
    });

    this.model.get('gst')?.valueChanges.subscribe((value) => {
      if (value) {
        this.model.get('gst')?.setValue(value.toUpperCase(), { emitEvent: false });
      }
    });

    const res = this.accountService.getLatestDocument('identity proof');
    this.pan = res['DocumentNumber'];
    this.hasBaseDropZoneOver = false;

    //disabled till we think of a better way to handle this
    //this.showITR = +MemoryStorageService.getItem('loanAmount') > 150000

    // this.uploader.onAfterAddingFile = (file) => {
    //   if (this.uploader.queue.length > this.maxFileCount) {
     
    //     this.uploader.removeFromQueue(file);
    //     swal.fire('Can not upload more than 3 files');
    //   }

    //   if(!this.model.get('businessDocument')?.value){
    //     swal.fire("Kindly select the Business Proof Type")
    //     this.uploader.removeFromQueue(file);
    //     return;
    //   }

    //   file.withCredentials = false;
    //   this.updatePreview();
    //   toBase64(file._file).then(base64 => {
    //     this.filesData[this.uploader.queue.length !== 0 ? this.uploader.queue.length -1 : 0 ] = { professionIdtype: this.model.get('businessDocument')?.value, type: this.getFileExtension(file._file.name), file: (base64 as string).split('base64,')[1] }
    //   });
      
    //   console.log(this.filesData)
    // };

  }

  removeAndShiftDocumentFile(arr: DocumentFile[], index: number): void {
    if (index >= 0 && index < arr.length) {
      arr.splice(index, 1);
    } else {
    }
  }

  private getFileExtension(fileName: string): string {
    const fileParts = fileName.split('.');
    return fileParts.length > 1 ? fileParts.pop()! : '';
  }

  ngOnInit(): void {
    this.spinner.hide();
    let user = JSON.parse(MemoryStorageService.getItem('user'));
    this.isBankStatementUpload = !!user['docs'][0]['bankstatement'];

    this.model.get('skipGst')?.valueChanges.subscribe(value => {
      this.filesData = [
        { professionIdtype: '', file: '', type: '' }, 
        { professionIdtype: '', file: '', type: '' }
      ]
      if (this.businessdoc) {
        this.businessdoc.nativeElement.value = '';
      }
      if (this.incomedoc) {
        this.incomedoc.nativeElement.value = '';
      }
      this.isMoved = value;
    });
    
  }

  updatePreview() {
    const files = this.uploader.queue;
    if (files.length == 0) {
      swal.fire('Only image files and PDFs are allowed');
    }

    // If the user selects a file, update the preview
    if (this.uploader.queue.length > 0) {
      this.selectedFile = this.uploader.queue[this.uploader.queue.length - 1]; // Show the last added file's preview
      this.generatePreviewUrl(this.selectedFile);
    }
    

    //this.image = files.length ? this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(files[0]._file)) : null;
  }

  generatePreviewUrl(file) {
    if (file.file.type.startsWith('image/')) {
      this.selectedFile.isImage = true;
      this.selectedFile.previewUrl = URL.createObjectURL(file._file);
    } else {
      this.selectedFile.isImage = false;
      this.selectedFile.previewUrl = file.file.size ? this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(file._file)) : null; 
    }
  }

  fileSelected(file){
    this.generatePreviewUrl(file)
  }

  removeFile(file) {
    const index = this.uploader.queue.indexOf(file);
    if (index !== -1) {
      this.uploader.removeFromQueue(file);
      // this.filesData[index] = { professionIdtype: '', file: '', type: '' }
      this.removeAndShiftDocumentFile(this.filesData, index)
      console.log(this.filesData)
    }
  }

  // removeFile() {
  //   this.uploader.clearQueue();
  //   this.image = false;
  // }

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  get isSelfEmployed() {
    return this.model.get('professionType')?.value === ProfessionType.SelfEmployed;
  }

  get isSalarized() {
    return this.model.get('professionType')?.value === ProfessionType.Salaried;
  }

  onProfessionTypeChange() {

    this.gstInactive = false;
    this.model.get('skipGst').reset();
    this.model.get('companyName').reset();
    this.model.get('companyType').reset();
    this.model.get('businessDocument').reset();
    this.model.get('gst').reset();

    // let user = JSON.parse(MemoryStorageService.getItem('user'));
    // if (this.isSalarized && user['docs'][0]['bankstatement']) {
    //   this.router.navigateByUrl('/assist-journey/bank-statement');
    // } 
    // else {
    //   environment.production ? this.router.navigateByUrl('/assist-journey/team-callback') : this.router.navigateByUrl('/assist-journey/nach-mandate');
    // }
  }

  submit() {
    if (this.model.valid) {
      this.spinner.show();

      //public professionTypes = [ProfessionType.Salaried, ProfessionType.SelfEmployed];
      //public companyTypes = [CompanyType.Proprietorship, CompanyType.Partnership, CompanyType.LimitedLiabilityPartnership, CompanyType.PrivateLimitedCompany];
      //public businessDocuments = [BusinessDocument.GumastaLicense, BusinessDocument.ShopEstablishmentCertificate, BusinessDocument.UdyogAadhaar];



      this.uploadBusinessDetails();
    }
  }

  async uploadBusinessDetails() {
    let isGST: boolean = this.model.get('gst')?.value !== '';
    const files = this.uploader.queue;
    const fileBase64 = await Promise.all(files.map((file) => toBase64(file._file)));

    let obj = {
      "occupationType": this.model.get('professionType')?.value,
      "companyName": this.model.get('companyName')?.value,
      "companyType": this.model.get('companyType')?.value,
      "professionIdType": isGST ? 'GST Number' : (!this.isSelfEmployed ? 'Salary slip' : this.model.get('businessDocument')?.value  ),
      "professionId": !this.model.get('skipGst')?.value ? this.model.get('gst')?.value : '',
      "isProprietor": this.isSelfEmployed && this.model.get('companyType')?.value === CompanyType.Proprietorship,
      "isGST": !this.model.get('skipGst')?.value,
    };

    try{
      
      let res = await lastValueFrom(this.accountService.uploadBusinessDetails(obj))
      if(res) {
        
        if(this.isSelfEmployed && this.model.get('companyType')?.value === CompanyType.Proprietorship && !this.model.get('skipGst')?.value && res && (typeof res !== 'string') && res.hasOwnProperty('data') && res['data'].hasOwnProperty('response_msg') && (res['data']['response_msg']).toLowerCase() != 'active' ){
          this.spinner.hide();
          swal.fire({
            text: "GST Number is In-Active, Kindly upload the document!",
            //icon: 'info',
            confirmButtonText: 'OK'
          }).then((result) => {
            if (result.isConfirmed) {
              this.hasDeactiveGst();
              return;
            }
          });
        }  
        else if (res && (res['errors'] || res['error']) && !this.model.get('skipGst')?.value) {
          this.spinner.hide();
          swal.fire({
            text: res['error'].hasOwnProperty('errors') ? (res['error'].errors + ', Kindly upload the document!') : (res['error'].hasOwnProperty('response_msg') ? 'GST Validation failed, Kindly upload the document!' : "Business Proof validation failed, Kindly upload the document!"),
            //icon: 'error',
            confirmButtonText: 'OK'
          }).then((result) => {
            if (result.isConfirmed) {
              this.hasDeactiveGst();
              return;
            }
          });   
        }
        
        this.spinner.hide();
        // if GST Number validation success then redirect to Bankstatement, Documents not required
        if(!this.model.get('skipGst')?.value){
          this.nextPageRouting();
        }
        else {
          // if Skip GST then Upload Documents
          this.spinner.show();
          let uploadFiles: DocumentFile[] = [];
          this.filesData.forEach(element => {
            if(element.professionIdtype){
              uploadFiles.push(element);
            }
          });
          let objupload = {
            "occupationType": this.model.get('professionType')?.value,
            "uploadFiles": uploadFiles
          };
          await this.accountService.uploadBusinessDocuments(objupload).subscribe(res => {
            if(res['data']){
              this.spinner.hide();
              this.nextPageRouting();
            }
            else {
              this.spinner.hide();
              swal.fire({
                text: "Unable to Upload",
                confirmButtonText: 'OK'
              }).then((result) => {
                  return;
              });  
              }
          });
        }
      }
    }
    catch(ex){
      this.spinner.hide();
      swal.fire({
        text: ex['error'].hasOwnProperty('errors') ? (ex['error'].errors + ', Kindly upload the document!') : "GST Validation Failed",
        //icon: 'error',
        confirmButtonText: 'OK'
      }).then((result) => {
        if (result.isConfirmed) {
          this.hasDeactiveGst();
          return;
        }
      });   
    }

  }

  nextPageRouting() {
    if (this.isSelfEmployed || this.isSalarized) {
      let user = JSON.parse(MemoryStorageService.getItem('user'));
      if (user['docs'][0]['bankstatement']) {
        // Used for LOS Bankstatement upload when professional details not found to open professionaldetails page
        MemoryStorageService.setItem('ProfessionalDetailsUploadPage', 'false');
        //
        return this.router.navigateByUrl('/assist-journey/bank-statement');
      } 
      // else {
      //   environment.production ? this.router.navigateByUrl('/assist-journey/team-callback') : this.router.navigateByUrl('/assist-journey/nach-mandate');
      // }
    } else {
      this.router.navigateByUrl('/assist-journey/process-offline');
    }
  }

  hasDeactiveGst(){
    this.model.get('gst').setValue('');
    this.model.get('skipGst').setValue(true);
    this.gstInactive = true;
  }

  onFileSelected(event: any, proofType: 'business' | 'income'): void {
    const file = event.target.files[0];
    if (file) {
      
      if(proofType === 'business'){
        if (file.type !== 'application/pdf' && !file.type.startsWith('image/')) {
          if (this.businessdoc) {
            this.businessdoc.nativeElement.value = '';
          }
          swal.fire("Only PDF or Image is supported for business document")
        }
        const reader = new FileReader();
        reader.onloadend = () => {
          const fileBase64 = reader.result as string;
          this.filesData[0] = { professionIdtype: 'Udyog Aadhaar', type: this.getFileExtension(file.name), file: fileBase64.split('base64,')[1] }
        };
        reader.readAsDataURL(file);
      }
      else if(proofType === 'income'){
        if (file.type !== 'application/pdf') {
          if (this.incomedoc) {
            this.incomedoc.nativeElement.value = '';
          }
          swal.fire("Only PDF or Image is supported for income document")
        }
        const reader = new FileReader();
        reader.onloadend = () => {
          const fileBase64 = reader.result as string;
          this.filesData[1] = { professionIdtype: 'ITR', type: this.getFileExtension(file.name), file: fileBase64.split('base64,')[1] }
        };
        reader.readAsDataURL(file);
      }
      console.log(this.filesData);
    }
  }

  comparePan() {
    // Your comparison logic goes here
  }
  
}
