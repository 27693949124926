import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { AccountService } from 'src/app/core/services/account.service';
import { UserService } from 'src/app/core/services/user.service';
import swal from 'sweetalert2';

@Component({
  selector: 'app-sorry',
  templateUrl: './sorry.component.html',
  styleUrls: ['./sorry.component.scss']
})
export class SorryComponent implements OnInit {
  applicationId: any;
  closeResult: string;
  showpopUp:boolean = false;

  constructor(
    private accountService: AccountService,private elRef: ElementRef, private renderer: Renderer2 , private UserService: UserService
  ) { }

  async ngOnInit() {
    // console.log(await this.UserService.encryptData('NMWYER1729581637242'));
    //console.log(await this.UserService.encryptData('PaymentCompleted'));
    // console.log(await this.UserService.encryptData('2001705942'));
    // console.log(await this.UserService.encryptData('913.00'));

    //console.log(await this.UserService.decryptData('a698e4103fe2716ee8754b881827dfc029578f6c08eda80391c52d2757e9c27c', ''));

    
    this.getCurrentApplication();
  }

  getCurrentApplication() {
    this.accountService.getCurrentApplicationStatus().subscribe(response => {

      this.applicationId = response.applicationCode;
      console.log(response , "app====");
    });
  }

  notifyMe(){
    var aplicationID = {
      ApplicationId: this.applicationId
    };
    this.accountService.notifyMe(aplicationID)
    .subscribe(res => {
      console.log(res, "respone---");
      if(res["data"].message == "Success" ){
        swal.fire("Your request submitted successfully. \n Thank you!!! ");
      }
    }, error => {
      console.log(error.error, "errrors-----");
      this.showpopUp = true;
      swal.fire(error.error.errors);
    });

  }
}
