<div class="row justify-content-center mt-3 d-flex flex-lg-row-reverse">
    <div class="col-lg-6 col-12">
        <div class="row justify-content-center mt-4">
            <div class="col-8 blue_box p-4 text-center">
                <h3 class="heading">Down Payment</h3>
            </div>
        </div>
        <div class="row justify-content-center mt-4">
            <div class="col-11 white_box p-4">
                <div class="row mt-1 justify-content-center">
                    <div class="col-10 text-center">
                        <img src="../../assets/images/assist-payment-processing.png" class="img" />
                        <h4 class="mt-4">Your payment is processing</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-5 col-11">
        <app-assist-left></app-assist-left>
    </div>
</div>
<ngx-spinner type="ball-spin-clockwise" size="medium"></ngx-spinner>


<div id="overlay-payment" class="overlay-payment" style="display: none;"></div>
<div id="popup-payment" class="popup-payment" style="display: none;">
    <p style="padding-top: 1rem;margin-bottom: -1rem;color: #002d3a;font-weight: 500;font-size: .9rem;margin-top: -1.5rem;">Having trouble with the pop-up, click <button style="border: none;background: white;padding: 0;color: blue;" (click)="OpenWindowByUser()">here</button> to access it</p>
    <p style="font-size: 2rem;color: #002d3a;font-weight: 400;font-family: revert-layer;">Processing</p>
    <div style="margin-top: -1rem;padding-top: -1rem;">
        <svg x="0px" y="0px" width="2.5rem" height="2.5rem" viewBox="0 0 48 48" class="reloading-icon">
            <path fill="#ff6f02" d="M8,24c0-8.8,7.2-16,16-16c1,0,2,0.1,3,0.3l0.7-3.9C26.5,4.1,25.3,4,24,4C12.9,4,4,13,4,24	c0,4.8,1.7,9.5,4.8,13.1l3-2.6C9.5,31.6,8,28,8,24z"></path>
            <path fill="#ff6f02" d="M39.5,11.3l-3.1,2.5C38.6,16.6,40,20.1,40,24c0,8.8-7.2,16-16,16c-1,0-2-0.1-3-0.3l-0.7,3.8	c1.3,0.2,2.5,0.3,3.7,0.3c11.1,0,20-8.9,20-20C44,19.4,42.4,14.8,39.5,11.3z"></path>
            <polygon fill="#ff6f02" points="31,7 44,8.7 33.3,19"></polygon>
            <polygon fill="#ff6f02" points="17,41 4,39.3 14.7,29"></polygon>
        </svg>
    </div>
    <p style="color: #002d3a;font-weight: 500;font-size: .9rem;/* margin-top: -1rem; */">Do not close or refresh this tab</p>
</div>