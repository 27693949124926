import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SubscribeService {

  private httpClient: HttpClient;
  // baseUrl: string = 'https://api.hubapi.com/crm/v3/objects/contacts';
   baseUrl: string = '';
  
   constructor(private httpBackend: HttpBackend) { 
     this.baseUrl = environment.apiUrl;
   }
   
   newsLetterSubscribe(subscribeData) {
     
      this.httpClient = new HttpClient(this.httpBackend);
     return this.httpClient.post(this.baseUrl + `SubscribeNewsLetter/PostNewsLetterSubscribe`, subscribeData 
     );
    
  }
}
