import { Component } from '@angular/core';

@Component({
  selector: 'app-assist-processing',
  templateUrl: './assist-processing.component.html',
  styleUrl: './assist-processing.component.scss'
})
export class AssistProcessingComponent {

}
