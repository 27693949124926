<div class="container-fluid rightSection">
    <div class="row m-0 justify-content-center">
        <div class="col-sm-9 col-lg-6 p-0">
            <div class="row d-flex formDiv">
                <div class="col-12 col-sm-12 rightSection">
                    <div class="row justify-content-center">
                        <div class="col-12">
                            <div class="signUp">
                                <div class="applicationForm">
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <h3>Upload your documents</h3>
                                            <p>All documents are securely stored. Only authorised personnel have
                                                access to them.</p>
                                        </div>
                                    </div>
                                    <div class="row justify-content-center">
                                        <div class="col-12 text-center">
                                            <div id="steps">
                                                <div class="step done" data-desc="Identity proof"><i class="fas fa-check"></i></div>
                                                <div class="step active" data-desc="Address Proof">2</div>
                                                <div class="step" data-desc="Business proof">3</div>
                                                <div class="step" data-desc="Bank statement">4</div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <!-- Identity-proof -->
                                            <div class="border-box Identity-proof">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <h2>Identity proof</h2>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-12">
                                                        <p>Please provide your PAN Card as identity proof</p>
                                                    </div>
                                                </div>
                                                <div class="row justify-content-center">
                                                    <div class="col-lg-6 col-sm-8  col-12 text-center">
                                                        <div class="file-upload">
                                                            <div class="image-upload-wrap">
                                                                <input class="file-upload-input" type='file' onchange="readURL(this);" accept="image/*" />
                                                                <div class="drag-text">
                                                                    <h3><i class="fas fa-upload"></i><br />
                                                                        Drag & drop or select file
                                                                    </h3>
                                                                </div>
                                                            </div>
                                                            <div class="file-upload-content d-none">
                                                                <img class="file-upload-image" src="../../../../assets/images/download (2).jpg" alt="your image" />
                                                                <p class="file-name">imd009128.jpg</p>
                                                                <button type="button" onclick="removeUpload()" class="btn btn-danger remove-image btn-sm"><i class="fas fa-times"></i></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row mt-3">
                                                    <div class="col-12 text-center">
                                                        <p class="text-success mb-0">Please upload the documents only in
                                                            JPEG, PNG or PDF format</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- Address-proof -->
                                            <div class="border-box Address-proof">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <h2>Address proof</h2>
                                                        <p>Please upload  Aadhaar Card</p>
                                                    </div>
                                                </div>
                                                <div class="row justify-content-center mt-2">
                                                    <div class="col-6 text-center">
                                                        <div class="file-upload">
                                                            <div class="image-upload-wrap d-none">
                                                                <input class="file-upload-input" type='file' onchange="readURL(this);" accept="image/*" />
                                                                <div class="drag-text">
                                                                    <h3> <i class="fas fa-upload"></i><br />
                                                                        Drag & drop or select file (front)
                                                                    </h3>
                                                                </div>
                                                            </div>
                                                            <div class="file-upload-content">
                                                                <img class="file-upload-image" src="../../../../assets/images/download (2).jpg" alt="your image" />
                                                                <p class="file-name">imd009128.jpg</p>
                                                                <button type="button" onclick="removeUpload()" class="btn btn-danger remove-image btn-sm"><i class="fas fa-times"></i></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-6 text-center">
                                                        <div class="file-upload">
                                                            <div class="image-upload-wrap d-none">
                                                                <input class="file-upload-input" type='file' onchange="readURL(this);" accept="image/*" />
                                                                <div class="drag-text">
                                                                    <h3> <i class="fas fa-upload"></i><br />
                                                                        Drag & drop or select file
                                                                        (Back)
                                                                    </h3>
                                                                </div>
                                                            </div>
                                                            <div class="file-upload-content">
                                                                <img class="file-upload-image" src="../../../../assets/images/download (2).jpg" alt="your image" />
                                                                <p class="file-name">imd009128.jpg</p>
                                                                <button type="button" onclick="removeUpload()" class="btn btn-danger remove-image btn-sm"><i class="fas fa-times"></i></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row mt-3">
                                                    <div class="col-12 text-center">
                                                        <p class="text-success mb-0">Please upload the documents only in
                                                            JPEG, PNG or PDF format</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="border-box Business-proof">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <h2>Business proof</h2>
                                                        <p>Please choose the document type you'd like to upload</p>
                                                    </div>
                                                    <div class="col-sm-8 col-md-10 col-lg-6 col-12">
                                                        <div class="form-group">
                                                            <select class="form-select">
                                                                <option>GST Number</option>
                                                                <option>Udyog Aadhaar Number</option>
                                                                <option>Shops & Establishment License</option>
                                                                <option>Gumasta Certificate</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row justify-content-center mt-1">
                                                    <div class="col-lg-6 col-sm-8  col-12 text-center">
                                                        <div class="file-upload">
                                                            <div class="image-upload-wrap d-none">
                                                                <input class="file-upload-input" type='file' onchange="readURL(this);" accept="image/*" />
                                                                <div class="drag-text">
                                                                    <h3><i class="fas fa-upload"></i><br />
                                                                        Drag & drop or select file
                                                                    </h3>
                                                                </div>
                                                            </div>
                                                            <div class="file-upload-content">
                                                                <img class="file-upload-image" src="../../../../assets/images/download (2).jpg" alt="your image" />
                                                                <p class="file-name">imd009128.jpg</p>
                                                                <button type="button" onclick="removeUpload()" class="btn btn-danger remove-image btn-sm"><i class="fas fa-times"></i></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row mt-4">
                                                    <div class="col-sm-8 col-md-10 col-lg-6 col-12">
                                                        <div class="form-group">
                                                            <label class="control-label">Please input your GST number
                                                                here</label>
                                                            <input type="text" class="form-control" placeholder="GST number" aria-label="GST" aria-describedby="basic-addon1">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="border-box Employment-proof">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <h2>Employment proof</h2>
                                                        <p>Latest 1 month salary slip required</p>
                                                    </div>
                                                </div>
                                                <div class="row justify-content-center mt-1">
                                                    <div class="col-lg-6 col-sm-8  col-12 text-center">
                                                        <div class="file-upload">
                                                            <div class="image-upload-wrap">
                                                                <input class="file-upload-input" type='file' onchange="readURL(this);" accept="image/*" />
                                                                <div class="drag-text">
                                                                    <h3><i class="fas fa-upload"></i><br />
                                                                        Drag & drop or select file
                                                                    </h3>
                                                                </div>
                                                            </div>
                                                            <div class="file-upload-content d-none">
                                                                <img class="file-upload-image" src="../../../../assets/images/download (2).jpg" alt="your image" />
                                                                <p class="file-name">imd009128.jpg</p>
                                                                <button type="button" onclick="removeUpload()" class="btn btn-danger remove-image btn-sm"><i class="fas fa-times"></i></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="border-box Bank-statement">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <h2>Bank statement</h2>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-6 col-md-4">
                                                        <div class="form-group"> <label class="control-label">Bank
                                                                name</label>
                                                            <div class="input-group"> <select class="form-select">
                                                                    <option>HDFC Bank</option>
                                                                    <option>Kotak Bank</option>
                                                                </select> </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-6 col-md-4">
                                                        <div class="form-group"> <label class="control-label">Account
                                                                type</label>
                                                            <div class="input-group"> <select class="form-select">
                                                                    <option>Saving</option>
                                                                    <option>Current</option>
                                                                </select> </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-6 col-md-4">
                                                        <div class="form-group">
                                                            <label class="control-label">Password</label>
                                                            <input type="Password" class="form-control" placeholder="Password" aria-label="Password" aria-describedby="basic-addon1">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row mt-3">
                                                    <div class="col-12">
                                                        <p class="font-weight-bold">You need to upload your Bank
                                                            statement here.</p>
                                                    </div>
                                                </div>
                                                <div class="row justify-content-center">
                                                    <div class="col-sm-6 col-12">
                                                        <div class="file-upload">
                                                            <div class="image-upload-wrap">
                                                                <input class="file-upload-input" type='file' onchange="readURL(this);" accept="image/*" />
                                                                <div class="drag-text">
                                                                    <h3> <i class="fas fa-upload"></i><br />
                                                                        Drag & drop or select file
                                                                    </h3>
                                                                </div>
                                                            </div>
                                                            <div class="file-upload-content d-none">
                                                                <img class="file-upload-image" src="../../../../assets/images/download (2).jpg" alt="your image" />
                                                                <p class="file-name">imd009128.jpg</p>
                                                                <button type="button" onclick="removeUpload()" class="btn btn-danger remove-image btn-sm"><i class="fas fa-times"></i></button>
                                                            </div>
                                                        </div>
                                                        <small>Statement starting from 24-09-2021 till
                                                            24-03-2022</small>
                                                    </div>
                                                    <div class="col-sm-6 col-12 text-left">
                                                        <ol>
                                                            <li title="saasdasdasdadasdsayweuduieeqeqooqoqoiqiqasas_epoqpqepqpqiq.jpg">
                                                                saasdasdasdadasdsayweuduieeqeqooqoqoiqiqasas_
                                                                epoqpqepqpqiq.jpg</li>
                                                            <li title="saasdasdasdadasdsayweuduieeqeqooqoqoiqiqasas_epoqpqepqpqiq.jpg">
                                                                saasdasdasdadasdsayweuduieeqeqooqoqoiqiqasas_
                                                                epoqpqepqpqiq.jpg</li>
                                                            <li title="saasdasdasdadasdsayweuduieeqeqooqoqoiqiqasas_epoqpqepqpqiq.jpg">
                                                                saasdasdasdadasdsayweuduieeqeqooqoqoiqiqasas_
                                                                epoqpqepqpqiq.jpg</li>
                                                            <li title="saasdasdasdadasdsayweuduieeqeqooqoqoiqiqasas_epoqpqepqpqiq.jpg">
                                                                saasdasdasdadasdsayweuduieeqeqooqoqoiqiqasas_
                                                                epoqpqepqpqiq.jpg</li>
                                                            <li title="saasdasdasdadasdsayweuduieeqeqooqoqoiqiqasas_epoqpqepqpqiq.jpg">
                                                                saasdasdasdadasdsayweuduieeqeqooqoqoiqiqasas_
                                                                epoqpqepqpqiq.jpg</li>
                                                            <li title="saasdasdasdadasdsayweuduieeqeqooqoqoiqiqasas_epoqpqepqpqiq.jpg">
                                                                saasdasdasdadasdsayweuduieeqeqooqoqoiqiqasas_
                                                                epoqpqepqpqiq.jpg</li>
                                                        </ol>
                                                    </div>
                                                </div>
                                                <div class="row mt-3">
                                                    <div class="col-12 text-center">
                                                        <p class="text-success mb-0">Please upload the documents only in
                                                            JPEG, PNG or PDF format</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12">
                                            <ul>
                                                <li>All content same as in current process </li>
                                                <li>Address proof - will have only one option - Adhaar, unlike in current process</li>
                                                <li>Bank statement to be asked - only if product is not splitfare</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="row justify-content-center text-center buttonDiv">
                                        <div class="col-sm-4 col-md-3 col-6">
                                            <button type="submit" class="btn btn-warning btn-block">Next</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>