<div class="row justify-content-center mt-4">
    <div class="col-8 blue_box p-4 text-center">
        <h3>Pay today <span>&#8377;
        {{PayToday | number:'1.0-0' }}
        </span></h3>
    </div>
</div>
<div class="row justify-content-center mt-4">
    <div class="col-11 white_box p-4">
        <table class="table table-sm table-borderless mb-4">
            <tbody>
                <tr>
                    <th scope="row">EMI</th>
                    <td>&#8377; 
                     {{EmiAmount.toLocaleString('en-IN')}}
                    </td>
                </tr>
                <tr>
                    <th scope="row">
                        Loan amount</th>
                    <td>
                       &#8377;
                       {{Loanamount.toLocaleString('en-IN') }} 
                       </td>
                </tr>
                <tr>
                    <th scope="row">
                        Tenure</th>
                    <td>{{NumberOfTotalEMI}}</td>
                </tr>
                <tr>
                    <th scope="row">
                        Downpayment</th>
                    <td>
                        &#8377;
        {{Downpayment.toLocaleString('en-IN') }}</td>
                </tr>
                <tr>
                    <th scope="row">
                        Processing Fee</th>
                    <td>
                        &#8377;
                        {{ProcessingFee.toLocaleString('en-IN')}}</td>
                </tr>
            </tbody>
        </table>
        <p class="note"><img src="../../assets/images/assist-icon-info.png" /> EMI due on 5th of every month</p>
        <p class="note">Your loan will be booked for amount of Rs. {{Loanamount.toLocaleString('en-IN')}}</p>
        <!-- {{FirstEMIDueDate.substring(0,2)}} -->
    </div>
</div>