import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MemoryStorageService } from 'src/app/core/services/memory-storage.service';
import { SessionTimerService } from '../../../core/services/session-timer.service';

@Component({
  selector: 'app-assist-show-payment-plans',
  templateUrl: './assist-show-payment-plans.component.html',
  styleUrls: ['./assist-show-payment-plans.component.scss'],
})
export class AssistShowPaymentPlansComponent implements OnInit {
  constructor(private router: Router, private sessionTimerService :SessionTimerService, private spinner: NgxSpinnerService) {
    const currentNavigation = this.router.getCurrentNavigation();
    const state = currentNavigation?.extras.state as { assistTravelPlans: string };
    this.assistTravelPlans = JSON.parse(state.assistTravelPlans);
  }

  inPrincipleAmount:number = 100000;
  assistTravelPlans: any = {};
  hasLimitExhausted: boolean = false;
  ngOnInit(): void {
    this.spinner.hide();
    this.hasLimitExhausted = MemoryStorageService.getItem("hasLimitExhausted") === 'true';
    if(this.hasLimitExhausted)
    { 
      this.sessionTimerService.SetShowTimer(false);
      this.sessionTimerService.stopTimer();

      //Not showing Exhause message and redirecting to manual Underwritting 
      this.hasLimitExhausted = false;
      this.router.navigateByUrl('/assist-journey/assist-underwrite');
      return
    }
    else{
      //this.sessionTimerService.resumeTimer();
    }
    // this.sessionTimerService.SetShowTimer(true);
    // this.sessionTimerService.resumeTimer();

    this.checkPlanEligibility(this.assistTravelPlans);

  }
  selectedPlanTenure: string | null = null;  
  selectedPlanId: string | null = null;  
  selectedPlan: string = '';
  planSelection(value: any, planId:any, tenure: any): void {
    this.selectedPlanTenure = tenure;
    this.selectedPlanId = planId;
    this.selectedPlan = value.split('/')[4];
  }
  submit() {
    this.spinner.show();
    this.router.navigateByUrl(
      '/assist-journey/'+this.selectedPlan
    );
  }

  checkPlanEligibility(plans: any[]) {
    const hasEligiblePlan = plans.some(plan => plan.plan_eligibility);

    if (!hasEligiblePlan) {
      return this.router.navigateByUrl('/assist-journey/assist-underwrite');
    }
  }
}
