import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MemoryStorageService } from 'src/app/core/services/memory-storage.service';
import { environment } from 'src/environments/environment';
import swal from 'sweetalert2';
import { AccountService } from '../../../core/services/account.service';
import { SessionTimerService } from '../../../core/services/session-timer.service';
@Component({
  selector: 'app-aadhaar-verification',
  templateUrl: './aadhaar-verification.component.html',
  styleUrls: ['./aadhaar-verification.component.scss'],
})
export class AadhaarVerificationComponent implements OnInit {
  @Input() apiUrl!: string;
  @Input() aadhaarOTPGenerated: boolean = false;
  @Output() apiResponse = new EventEmitter();

  public myForm: UntypedFormGroup;
  public myForm1: UntypedFormGroup;
  resetButtonEnable: boolean = false;
  startTime: number;

  baseUrl!: string;
  constructor(private router: Router, private formBuilder: UntypedFormBuilder, private http: HttpClient, private spinner: NgxSpinnerService, private accountService: AccountService, private sessionTimerService :SessionTimerService) {
    this.baseUrl = environment.apiUrl + 'assistmodule/v1/createUserProfile';
    // this.myForm = formBuilder.group({
    //   documentNumber: [
    //     '',
    //     [Validators.required, Validators.pattern('^[0-9]{12}$')],
    //   ],
    //   requestCode: ['5025'],
    //   aadhaarRequestType: ['initiate'],
    // });

    // this.myForm1 = formBuilder.group({
    //   documentNumber: [
    //     '',
    //     [Validators.required, Validators.pattern('^[0-9]{12}$')],
    //   ],
    //   requestCode: ['5021'],
    //   aadhaarRequestType: ['initiate'],
    // });
  }

  get m() {
    return this.myForm.controls;
  }

  get s() {
    return this.myForm1.controls;
  }

  onSubmit() {
    console.log(this.myForm.value);
    this.spinner.show();
    return this.http.post(this.baseUrl, this.myForm.value).subscribe({
      next: (response: any) => {
        console.log(response);
        this.spinner.hide();
        if (response['error'] == null) {
          this.aadhaarOTPGenerated = true;
          swal.fire('Aadhaar OTP Sent');
          this.myForm.reset();
        } else {
          swal.fire(
            response['error']['response_msg'] == null ||
              response['error']['response_msg'] == ''
              ? response['error']['response_summary']
              : response['error']['response_msg']
          );
        }
        this.apiResponse.emit();
      },
    });
  }

  onSubmitOtp() {
    this.myForm1.value['docType'] = this.myForm1.value['docType'] == null ? 'address proof' : this.myForm1.value['docType'];
    this.myForm1.value['requestCode'] = this.myForm1.value['requestCode'] == null ? '5021' : this.myForm1.value['requestCode'];
    this.myForm1.value['subDocType'] = this.myForm1.value['subDocType'] == null ? 'aadhaar' : this.myForm1.value['subDocType'];
    console.log(this.myForm1.value);
    this.spinner.show();
    return this.http.post(this.baseUrl, this.myForm1.value).subscribe({
      next: (response: any) => {
        console.log(response);
        this.spinner.hide();
        if (response['error'] == null) {
          this.aadhaarOTPGenerated = true;
          //swal.fire('Aadhaar OTP Verified Successfully!');
          swal.fire({
            text: 'Aadhaar OTP Verified Successfully!',
            //icon: 'info',
            confirmButtonText: 'OK'
          }).then((result) => {
            if (result.isConfirmed) {
              this.myForm1.reset();
              let user = JSON.parse(MemoryStorageService.getItem('user'));
              this.router.navigateByUrl('/assist-journey/professional-details');
            }
          });
          // this.myForm1.reset();
          // let user = JSON.parse(MemoryStorageService.getItem('user'));
          // //redirect to /assist-journey/professional-details
          // this.router.navigateByUrl('/assist-journey/professional-details');
          // if (user['docs'][0]['bankstatement']) {
          //   this.router.navigateByUrl('/assist-journey/bank-statement');
          // }
          // else {
          //   this.router.navigateByUrl('/assist-journey/nach-mandate');
          // }
        } else {
          swal.fire(
            response['error']['response_msg'] == null ||
              response['error']['response_msg'] == ''
              ? response['error']['response_summary']
              : response['error']['response_msg']
          );
          this.resetButtonEnable = true;
        }
        this.apiResponse.emit();
      },
    });
  }

  ngOnInit(): void {
    this.spinner.hide();
    //this.sessionTimerService.resumeTimer();
    console.log('ngOnInit');
    this.startTimer();
    //Check if aadhaar doc exist and it is success verify
    this.myForm = this.formBuilder.group({
      documentNumber: [
        '',
        [Validators.required, Validators.pattern('^[0-9]{12}$')],
      ],
      requestCode: ['5025'],
      aadhaarRequestType: ['initiate'],
    });
    this.myForm1 = this.formBuilder.group({
      aadhaarOtp: ['', [Validators.required, Validators.pattern('^[0-9]{6}$')]],
      requestCode: ['5021'],
      docType: ['address proof'],
      subDocType: ['aadhaar'],
    });
    let documentType = 'address proof';
    this.accountService.getLatestDocument(documentType).subscribe(
      (res) => {
        console.log(res);
        if (res.status == 'Success' && res.api == 'VERIFY') {
        }else{
          this.myForm.controls.documentNumber.setValue(res.documentNumber);
          this.onSubmit();
        }
      }
    )

  }
  onResendOTP() {
    let objResendAadhaarOtp =
    {
      requestCode: '5025',
      aadhaarRequestType: 'resend'
    }
    this.accountService.ResendAadhaarOtp(objResendAadhaarOtp).subscribe({
      next: (response: any) => {
        console.log(response);
        this.spinner.hide();
        if (response['error'] == null) {
          this.aadhaarOTPGenerated = true;
          swal.fire('Aadhaar OTP Resent');
          this.myForm1.reset();
          this.resetButtonEnable = false;
        } else {
          swal.fire(
            response['error']['response_msg'] == null ||
              response['error']['response_msg'] == ''
              ? response['error']['response_summary']
              : response['error']['response_msg']
          );
        }
        this.apiResponse.emit();
      },

    });

  }
  startTimer(): void {
    // Get the current time in milliseconds
    this.startTime = Date.now();

    // Start a timer using setTimeout or setInterval
    setTimeout(() => {
      const currentTime = Date.now();
      const elapsedTimeInMinutes = (currentTime - this.startTime) / (1000 * 60);

      // Check if elapsed time is greater than 1 minutes (600,000 milliseconds)
      if (elapsedTimeInMinutes > 1) {
        this.resetButtonEnable = true;
      }
    }, 600000); // 600,000 milliseconds = 10 minutes
  }
}
