import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { FlightItinerary } from '../models/FlightItinerary';
import { IFlightRow } from '../models/IFlightRow';

@Injectable({
    providedIn: 'root'
})
export class SharedDataService {

    private inPrincipleCreditLimitSource = new ReplaySubject<string>(1);
    inPrincipleCreditLimit = this.inPrincipleCreditLimitSource.asObservable();

    private applicationMenuToggle = new ReplaySubject<boolean>(1);
    applicationMenuDisplay = this.applicationMenuToggle.asObservable();

    private educationDisplay = new ReplaySubject<string>(1);
    educationTabDisplay = this.educationDisplay.asObservable();

    private locationObj = new ReplaySubject<any>(1);
    locationList = this.locationObj.asObservable();
    
    private menuItemListSource = new ReplaySubject<IMenuItem[]>(1)
    menuItemList$ = this.menuItemListSource.asObservable();

    private airportListSource = new ReplaySubject<FlightItinerary[]>(1)
    airportList$ = this.airportListSource.asObservable();


    private filterStopsAirportListSource = new ReplaySubject<IFlightRow[]>(1)
    filterStopsAirportList$ = this.filterStopsAirportListSource.asObservable();

    private staticMenuItemList: Array<IMenuItem> = [
        {
            "Name": "AboutUs",
            "Visible": true
        },
        {
            "Name": "Products",
            "Visible": true
        },
        {
            "Name": "Book",
            "Visible": true
        },
        {
            "Name": "PartnerDirectory",
            "Visible": true
        },
        {
            "Name": "Partners",
            "Visible": true
        },
        {
            "Name": "Explore",
            "Visible": true
        },
        {
            "Name": "ContactDetails",
            "Visible": true
        }
    ];  
    constructor(){
        this.setMenuVisibility(this.staticMenuItemList);
    }

    setLocation(locationList: any) {
        this.locationObj.next(locationList);
    }

    setInPrincipleCredit(inPrincipleCreditLimit: string) {
        this.inPrincipleCreditLimitSource.next(inPrincipleCreditLimit);
    }

    setEducationDisplay(educationTabDisplay: string) {
        this.educationDisplay.next(educationTabDisplay);
    }

    setApplicationMenuDisplay(applicationMenuDisplay: boolean) {
        this.applicationMenuToggle.next(applicationMenuDisplay);
    }

    setMenuVisibility(menuItemList: Array<IMenuItem>) {
        this.menuItemListSource.next(menuItemList);
    }

    setDefaultMenuVisibility() {
        this.staticMenuItemList.forEach(item => {
            item.Visible = true;
        });
        this.menuItemListSource.next(this.staticMenuItemList);
    }

    getMenuItems() {
        return this.staticMenuItemList; 
    }

    setAirportList(airportList: Array<FlightItinerary>) {
        this.airportListSource.next(airportList);
    }

    setFilterStopsAirportList(filterStopsAirportList: Array<IFlightRow>) {
        this.filterStopsAirportListSource.next(filterStopsAirportList);
    }
}

export interface IMenuItem {
    Name: string;
    Visible: boolean;
}
