import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { SessionTimerService } from '../../../core/services/session-timer.service';

@Component({
  selector: 'app-assist-team-callback',
  templateUrl: './assist-team-callback.component.html',
  styleUrls: ['./assist-team-callback.component.scss']
})
export class AssistTeamCallbackComponent implements OnInit {

  constructor(private sessionTimerService :SessionTimerService, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.spinner.hide();
    this.sessionTimerService.SetShowTimer(false);
    this.sessionTimerService.stopTimer();
  }

}
