<div class="row justify-content-center mt-3 d-flex flex-lg-row-reverse">
    <div class="col-lg-6 col-12">
        <div class="row justify-content-center mt-4">
            <div class="col-8 blue_box p-4 text-center">
                <h3 class="heading">In progress</h3>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-11 white_box p-4">
                <div class="row mt-4 justify-content-center">
                    <div class="col-10 text-center">
                        <h3 class="mt-2">Your loan application is in progress</h3>
                        <p>We appreciate your interest in our services. Your loan application is in review by our team
                            of experts. They will carefully assess your financial situation to ensure a personalized
                            evaluation.</p>
                        <p>Please expect a response within 24 to 48 hours. If you have any urgent queries, please
                            <b>contact us at 91-9930133163.</b></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-5 col-11">
        <app-assist-left></app-assist-left>
    </div>

</div>
<ngx-spinner type="ball-spin-clockwise" size="medium"></ngx-spinner>