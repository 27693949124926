<div class="row justify-content-center mt-3 d-flex flex-sm-row-reverse">
    <div class="col-lg-6 col-12">
        <div class="row justify-content-center mt-lg-5">
            <div class="col-9 col-sm-8 blue_box p-4 text-center">
                <h3 class="heading">Confirm your details</h3>
            </div>
        </div>
        <div class="row m-0 justify-content-center">
            <div class="col-12 col-sm-11 white_box p-4">
                <form class="container-fluid page mt-60" ngForm #form="ngForm" (ngSubmit)="submit()" autocomplete="off">
                    <div class="row mt-4">
                        <div class="col-12 col-sm-6">
                            <div class="form-group">
                                <label for="panid" class="control-label">PAN</label>
                                <input type="text" name="panid" [(ngModel)]="formReq.panid" #panid="ngModel" required class="form-control" placeholder="Enter your PAN" pattern="^[A-Z]{5}[0-9]{4}[A-Z]{1}$" maxlength="10" minlength="10" [readonly]="panFetched" (change)="calculateAge()" [min]="minDate" [max]="maxDate" (input)="capitalizeInput('panid')">
                                <div *ngIf="panid.invalid && (panid.dirty || panid.touched)" class="error">
                                    <div *ngIf="panid.errors.required">
                                        PAN is required.
                                    </div>
                                    <div *ngIf="panid.errors.pattern">
                                        PAN must be in the format AAAAA9999A.
                                    </div>
                                    <div *ngIf="panid.errors.minlength">
                                        PAN must be 10 characters long.
                                    </div>
                                    <div *ngIf="panid.errors.maxlength">
                                        PAN must be 10 characters long.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6">
                            <div class="form-group">
                                <label for="dob" class="control-label">Date of birth</label>
                                <input type="date" name="dob" [(ngModel)]="formReq.dob" #dob="ngModel" class="form-control" placeholder="Select your date of birth" (change)="formatDate($event)" style="position: relative" [readonly]="dobFetched">
                                <input type="text" name="dobui" [(ngModel)]="formReq.dobui" style="position: absolute; top: 40%; border: none; outline: none; font-family: 'Roboto', sans-serif; font-size: 0.9rem; font-weight: 400; color: #474747;" readonly>
                                <div *ngIf="form.dob">
                                    <small>You are {{ age }} years old.</small>
                                </div>
                                <div *ngIf="age<18 || age>58" class="error">
                                    Age must be between 18 and 58 years old.
                                </div>
                                <div *ngIf="dob.invalid && (dob.dirty || dob.touched)" class="error">
                                    <div *ngIf="dob.errors.required">
                                        Date of birth is required.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6">
                            <div class="form-group">
                                <label for="gender" class="control-label">Gender</label>
                                <select name="gender" [(ngModel)]="formReq.gender" #gender="ngModel" class="form-select" [disabled]="genderFetched">
                                    <option disabled selected>Choose your gender</option>
                                    <option>Male</option>
                                    <option>Female</option>
                                    <option>Other</option>
                                </select>
                                <div *ngIf="gender.invalid && (gender.dirty || gender.touched)" class="error">
                                    <div *ngIf="gender.errors.required">
                                        Please select a gender.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6">
                            <div class="form-group">
                                <label for="pinCode" class="control-label">Pin code</label>
                                <input type="text" name="pinCode" [(ngModel)]="formReq.pincode" #pincode="ngModel" required class="form-control" placeholder="Enter your pin code" pattern="^[0-9]{6}$" maxlength="6" minlength="6" (keypress)="allowOnlyNumbers($event)" [readonly]="pincodeFetched">
                                <div *ngIf="pincode.invalid && (pincode.dirty || pincode.touched)" class="error">
                                    <div *ngIf="pincode.errors.required">
                                        Pin code is required.
                                    </div>
                                    <div *ngIf="pincode.errors.pattern">
                                        Pin code must be 6 digits.
                                    </div>
                                    <div *ngIf="pincode.errors.minlength || pincode.errors.maxlength">
                                        Pin code must be 6 digits.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center" *ngIf="showConfirmCheckbox">
                        <div class="col-12">
                            <div class="boxes">
                                <input type="checkbox" id="box1" class="form-check-input" [(ngModel)]="formReq.iConfirm" name="checkbox" #iConfirm="ngModel" required>
                                <label for="box1">I confirm above details</label>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center text-center buttonDiv">
                        <div class="col-12">
                            <button type="submit" class="btn btn-warning mt-3" [disabled]="(panid.invalid || gender.invalid || dob.invalid || formReq.dob == '' || formReq.dob === 'undefined' || pincode.invalid || (!formReq.iConfirm && showConfirmCheckbox))"> Submit </button>
                        </div>
                    </div>
                    <div class="row justify-content-center ng-star-inserted">
                        <div class="col-lg-10 col-md-10 col-sm-12" style="text-align: center; font-size: 0.9rem; font-style: italic;">
                            <div class="boxes">
                                <label  for="box1">
                                    <span style="color: red">* </span>
                                    In case any of the personal details are incorrect then please connect with us on support&#64;mihuru.com
                                </label>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="col-lg-5 col-12 pt-4">
        <app-assist-left></app-assist-left>
    </div>
</div>

<ngx-spinner type="ball-spin-clockwise" size="medium"></ngx-spinner>
<div id="overlay-credit-phone" class="overlay-credit-phone" style="display: none;"></div>
<div id="popup-credit-phone" class="popup-credit-phone" style="display: none;">
    <div class="row justify-content-center mt-3 d-flex flex-sm-row-reverse">
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="row justify-content-center mt-lg-5">
                <div class="col-9 col-sm-8 blue_box p-4 text-center">
                    <h3 class="heading">Confirm your details</h3>
                </div>
            </div>
            <div class="row m-0 justify-content-center">
                <div class="col-12 col-sm-11 white_box p-4">
                    <div class="container-fluid page mt-60">
                        <form [formGroup]="CreditUpdate" *ngIf="showMobileNo" >
                            <div class="error">{{creditPopUpErrorMessage}}</div>
                            <div class="row mt-4">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="mobileNo" class="control-label">Mobile Number</label>
                                        <input type="text" formControlName="mobileNo" required class="form-control" placeholder="Enter Mobile Number" pattern="^[A-Z]{5}[0-9]{4}[A-Z]{1}$" maxlength="10" minlength="10">
                                        <div *ngIf="CreditUpdate.get('mobileNo')?.value.slice(-5) !== creditMobileNo.slice(-5) && (CreditUpdate.get('mobileNo')?.value).length > 1" class="error">
                                                Enter Correct Mobile Number
                                        </div>
                                    </div>
                                </div>        
                            </div>
                            <div class="row justify-content-center text-center buttonDiv">
                                <div class="col-12">
                                    <button (click)="SendAlternativeOTP(CreditUpdate.get('mobileNo')?.value)" class="btn btn-warning mt-3" [attr.disabled]="(CreditUpdate.get('mobileNo')?.value.slice(-5) !== creditMobileNo.slice(-5)  && (CreditUpdate.get('mobileNo')?.value).length > 1 ) ? true : null"> Submit </button>
                                </div>
                            </div>
                        </form>
                        <form [formGroup]="CreditUpdateConfirm"  *ngIf="!showMobileNo">
                            <div class="row mt-4">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="mobileOTP" class="control-label">Enter OTP</label>
                                        <input type="text" formControlName="mobileOTP" required class="form-control" placeholder="Enter OTP" maxlength="4" minlength="4">
                                        <div *ngIf="((CreditUpdateConfirm.get('mobileOTP')?.value).length !== 4)" class="error">
                                            Enter Correct OTP
                                        </div>
                                        <div class="timer-container">
                                            <span *ngIf="!resendAvailable">{{ timeRemaining }}s</span>
                                            <button *ngIf="resendAvailable" class="btn btn-link btn-response" (click)="resendOtp()">
                                                <i class="fa fa-paper-plane" aria-hidden="true" style="color: #044b5f;font-size:1em;"></i>
                                                <svg height="20" width="55" style="font-weight: unset;">
                                                    <text x="5" y="13" fill="blue" font-size="1em" style="font-weight: 500;">Resend</text>
                                               </svg>
                                            </button>
                                        </div>
                                    </div>
                                </div>        
                            </div>
                            <div class="row justify-content-center text-center buttonDiv">
                                <div class="col-12">
                                    <button (click)="ConfirmNewNumber(CreditUpdateConfirm.get('mobileOTP')?.value)" class="btn btn-warning mt-3" [attr.disabled]="((CreditUpdateConfirm.get('mobileOTP')?.value).length !== 4) ? true : null"> Submit </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>