import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { delay, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UserPersonalDetails } from '../models/applicationUser';


const emptyPersonalInfo: UserPersonalDetails = {};

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private _userPersonalDetails = new BehaviorSubject<UserPersonalDetails>({});
  private dataStore: { userPersonalDetails: UserPersonalDetails } = { userPersonalDetails: {} };
  readonly userPersonalDetails = this._userPersonalDetails.asObservable();

  baseUrl: string = '';

  constructor(private httpClient: HttpClient) {
    this.baseUrl = environment.apiUrl;
   }

  getUserData() {
   
    return of<UserPersonalDetails>(emptyPersonalInfo)
    .pipe(
      tap(emptyPersonalInfo => {
        this.dataStore.userPersonalDetails = emptyPersonalInfo;
        this._userPersonalDetails.next(Object.assign({}, this.dataStore).userPersonalDetails);
      }),
      delay(2000)
    );
  }

  getTeam(obj){
    return this.httpClient.post(this.baseUrl + 'OurTeam/getOurTeamOrPresence', obj);
  }

  getLendingPartner(obj){
    return this.httpClient.post(this.baseUrl + 'PartnerLogs/PartnerLogs', obj);
  }

  getTestimonialMedia(obj){
    return this.httpClient.post(this.baseUrl + 'OurTeam/getTestimonialMedia', obj);
  }

  async importKey(rawKey: string): Promise<CryptoKey> {
    const encoder = new TextEncoder();
    return window.crypto.subtle.importKey(
      'raw',
      encoder.encode(rawKey),
      { name: 'AES-CBC' },
      false,
      ['encrypt', 'decrypt']
    );
  }

  getApplicationTokenByApplicationId(Id: number){
    return this.httpClient.get(this.baseUrl + 'User/generate/token/'+Id, { responseType: 'text' });
  }
  
  getUserPlanDetailsforBSUpload(Id: number, token: string){
    let headers1 = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this.httpClient.post(this.baseUrl + 'User/getUserPlanDetailsforBSUpload', { applicationId: Id, token: token }, { headers: headers1 });
  }

  async encryptData(data: string): Promise<string> {
    const cryptoKey = await this.importKey(environment.sterlingEncryptionKey);
    const iv = new Uint8Array(16); 
    
    const encoder = new TextEncoder();
    const dataBuffer = encoder.encode(data);
    
    const encryptedBuffer = await window.crypto.subtle.encrypt(
      {
        name: 'AES-CBC',
        iv: iv,
      },
      cryptoKey,
      dataBuffer
    );
  
    return Array.from(new Uint8Array(encryptedBuffer))
      .map(byte => byte.toString(16).padStart(2, '0'))
      .join('');
  }
  
  async decryptData(encryptedData: string, key: string): Promise<string> {
    const cryptoKey = await this.importKey(environment.sterlingEncryptionKey);
    const iv = new Uint8Array(16); 
    
    const dataBuffer = new Uint8Array(encryptedData.match(/.{1,2}/g)!.map(byte => parseInt(byte, 16)));
  
    const decryptedBuffer = await window.crypto.subtle.decrypt(
      {
        name: 'AES-CBC',
        iv: iv,
      },
      cryptoKey,
      dataBuffer
    );
  
    const decoder = new TextDecoder();
    return decoder.decode(decryptedBuffer);
  }
  
  async redirectWithPost(url: string, data: any) {
    const form = document.createElement('form');
    form.action = url;

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const hiddenField = document.createElement('input');
        hiddenField.type = 'hidden';
        hiddenField.name = key;
        hiddenField.value = await this.encryptData(data[key]);

        form.appendChild(hiddenField);
      }
    }

    document.body.appendChild(form);

    form.submit();
  }

  toPascalCase(word: string): string {
    return word
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }
  

}
