<div class="mt-4">
    <div class="row user_details">
        <div class="col-sm-11 col-12 mb-4">
            <p><span>Hi {{FirstName}}! </span>You are just a step away from financing your trip! Please confirm the
                following details to proceed.</p>
        </div>
    </div>
    <div class="row content_div">
        <div class="col-sm-6 col-12">
            <div class="table"><img src="../../assets/images/assist-contact.png" />
                <p>
                    {{Mobile}}
                </p>
            </div>
        </div>
        <div class="col-sm-6 col-12 hideScrollBar" style="overflow-y: hidden;">
            <div class="table"><img src="../../assets/images/assist-email.png" />
                <p>
                    {{Email}}
                </p>
            </div>
        </div>
        <div class="col-sm-6 col-12">
            <div class="table"><img src="../../assets/images/assist-pan.png" />
                <p>
                    {{PAN}}
                </p>
            </div>
        </div>
        <div class="col-sm-6 col-12">
            <div class="table"><img src="../../assets/images/assist-birthdate.png" />
                <p>
                    {{DOB}}
                </p>
            </div>
        </div>
        <div class="col-sm-6 col-12">
            <div class="table"><img src="../../assets/images/assist-location.png" />
                <p>
                    {{Address | titleCase}}
                </p>
            </div>
        </div>
        <div class="col-sm-6 col-12" *ngIf="DocsRequired!=''">
            <div class="table"><img src="../../assets/images/assist-docs.png" />
                <p>
                    {{DocsRequired}}
                </p>
            </div>
        </div>
    </div>
    <div class="row justify-content-center text-center buttonDiv mt-3">
        <div class="col-sm-12 col-md-12 col-12">
            <div *ngIf="!hideChangePlanButton">
                <button class="btn btn-warning" style="padding-inline: 1.25rem; margin-top: 1rem; margin-inline: 1rem;"  (click)="ChangePlan()">Change Plan</button>
            </div>
            <button class="btn btn-warning" style="margin-top: 1rem; margin-inline: 1rem;" (click)="ProceedToDocUpload()"> {{btnProceedText}} </button>
        </div>
        <!-- <div class="col-sm-6 col-md-6 col-10">
            <button class="btn btn-warning" (click)="ProceedToDocUpload()"> {{btnProceedText}} </button>
        </div> -->
        <div class="col-12">
            <p class="note">
                We have found this data on government databases based on your mobile number
            </p>
        </div>
        <div class="col-12" style="text-align: center;">
            <p class="note">Check Your Credit Report <button style="border: none;background: white;padding: 0;color: blue;" (click)="OpenCreditPDF()">here</button></p>
        </div>
    </div>
</div>
<ngx-spinner type="ball-spin-clockwise" size="medium"></ngx-spinner>