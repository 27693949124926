import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FileItem, FileUploader, FileUploaderOptions } from 'ng2-file-upload';
import { NgxSpinnerService } from 'ngx-spinner';
import swal from 'sweetalert2';

const toBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

function fileValidation(files: FileItem[]) {
  const sizeExceed = files.filter((file) => file._file.size >= 2048000);
  if (sizeExceed.length) {
    swal.fire('Size exceed');
    return false;
  }
  const invalidFiles = files.filter(
    (file) =>
      !['image/bmp', 'image/jpeg', 'image/png', 'application/pdf'].includes(
        file._file.type
      )
  );
  if (invalidFiles.length) {
    swal.fire('Invalid File Type');
    return false;
  }
  return true;
}

@Component({
  selector: 'app-upload-and-preview',
  templateUrl: './upload-and-preview.component.html',
  styleUrls: ['./upload-and-preview.component.scss'],
})
export class UploadAndPreviewComponent implements OnInit {
  @Input() maxFileCount!: number;
  @Input() apiUrl!: string;
  @Input() requestCode!: string;
  @Input() docType!: string;
  @Input() subDocType!: string;
  @Output() apiResponse = new EventEmitter();

  selectedFiles: string[] = [];

  async upload() {
    const files = this.uploader.queue;
    if (!fileValidation(files)) {
      return;
    }

    const fileBase64 = await Promise.all(
      files.map((file) => toBase64(file._file))
    );

    const body = {
      requestCode: this.requestCode,
      docType: this.docType,
      subDocType: this.subDocType,
      fileType: files[0]._file.type === 'application/pdf' ? 'pdf' : 'image',
      files: fileBase64,
      FileName:files[0]._file.name,
    };

    this.spinner.show();
    this.http.post(this.apiUrl, body).subscribe({
      next: (response: any) => {
        this.spinner.hide();
        this.apiResponse.emit(response);
        this.uploader.clearQueue();
        this.selectedFiles = [];
      },
      error: (error: any) => {
        this.spinner.hide();
        swal.fire('Upload failed', error.message, 'error');
      }
    });
  }

  onFileSelected() {
    this.updateSelectedFiles();
  }

  removeFile(item: FileItem, event: MouseEvent): void {
    event.stopPropagation();
    if(item && this.uploader.queue.includes(item)){
      this.uploader.removeFromQueue(item);
    }
  }

  uploaderOptions: FileUploaderOptions = {
    url: 'https://mihuru.com',
    queueLimit: this.maxFileCount,
    disableMultipart: true,
    formatDataFunctionIsAsync: true,
    formatDataFunction: async (item: FileItem) => {
      return {
        name: item.file.name,
        length: item.file.size,
        contentType: item.file.type,
        date: new Date(),
      };
    },
  };

  uploader = new FileUploader(this.uploaderOptions);
  hasBaseDropZoneOver: boolean;
  hasAnotherDropZoneOver: boolean;
  response: string;

  constructor(
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private spinner: NgxSpinnerService
  ) {
    this.hasBaseDropZoneOver = false;
    this.hasAnotherDropZoneOver = false;

    this.response = '';

    this.uploader.response.subscribe((res: any) => (this.response = res));
  }

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  public fileOverAnother(e: any): void {
    this.hasAnotherDropZoneOver = e;
  }

  ngOnInit(): void {
    this.uploader.onAfterAddingFile = (fileItem: FileItem) => {
      if (this.uploader.queue.length > this.maxFileCount) {
        this.uploader.removeFromQueue(fileItem);
        swal.fire(`You can only upload up to ${this.maxFileCount} files`);
      }
      this.updateSelectedFiles();
    };
  }

  private updateSelectedFiles(): void {
    this.selectedFiles = this.uploader.queue.map((fileItem: FileItem) => fileItem.file.name);
  }
}
