import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-assist-right',
  templateUrl: './assist-right.component.html',
  styleUrls: ['./assist-right.component.scss']
})
export class AssistRightComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
