<div class="row text-center mt-5">
    <div class="col-6 border-right p-3 align-items-center">
        <img src="../../assets/images/assist-icon-01.png" class="w-30" />
        <p>Budget friendly travel credit line</p>
    </div>
    <div class="col-6 p-3 align-items-center ">
        <img src="../../assets/images/assist-icon-02.png" class="w-30" />
        <p>No hidden charges</p>
    </div>
</div>
<div class="row mt-4 mb-4 text-center justify-content-around">
    <div class="col-5 border-top"></div>
    <div class="col-5 border-top"></div>
</div>
<div class="row mb-4 text-center">
    <div class="col-6 border-right mb-4 p-3 align-items-center">
        <img src="../../assets/images/assist-icon-03.png" class="w-30" />
        <p>Ease of payment with AutoPay</p>
    </div>
    <div class="col-6 mb-4 p-3 align-items-center">
        <img src="../../assets/images/assist-icon-04.png" class="w-30" />
        <p>Lightning fast support</p>
    </div>
</div>