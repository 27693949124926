import { Directive, ElementRef, HostListener } from '@angular/core';
import Swal from 'sweetalert2';

@Directive({
  selector: '[appMobileNumberPattern]'
})
export class MobileNumberPatternDirective {
  constructor(private _el: ElementRef) { }

  @HostListener('blur', ['$event']) onBlur(event) {
    const initalValue = this._el.nativeElement.value;
    if (initalValue != "" && initalValue != undefined) {
      const regex = new RegExp('^[0-9]{10}$');
      const valid = regex.test(initalValue);
      if (!valid) {
        this._el.nativeElement.focus();
        Swal.fire("Oops, this number is invalid. Please re-enter your 10-digit mobile number");
        this._el.nativeElement.value = "";
      }
    }
  }
}
