<div class="row justify-content-center m-0 flightTicketBox">
    <div class="col-lg-12 p-0 ticketBoxDiv">
        <div class="pb-0 payment-plan">
            <!-- <div *ngIf="hasLimitExhausted">
                <div class="row justify-content-center">
                    <div class="col col-lg-4 col-md-4">
                        <h3>Sorry!</h3>
                        <p style="text-align: center;">You have exhausted your limit</p>
                        <div style="text-align: center;">
                            <img src="../../../../assets/images/creditLimit.png" alt="credit Limit" style="height: 5rem;">
                        </div>
                    </div>
                </div>
            </div> -->
            <div *ngIf="!hasLimitExhausted">
                <div class="row justify-content-center">
                    <!-- 01 -->
                    <div *ngFor="let plan of assistTravelPlans" class="col-12 col-md-6 col-lg-3 mb-4">
                        <button class="image-flip h-100 w-100 btn" (click)="planSelection(plan.navigate_url, plan.plan_id, plan.tenor)" [class.selected-plan]="plan.plan_id === selectedPlanId && plan.tenor === selectedPlanTenure" [disabled] ="!plan.plan_eligibility">
                            <div class="mainflip flip-0 h-100">
                                <div class="frontside">
                                    <div class="card h-100">
                                        <div class="card-body align-items-center">
                                            <div class="d-flex h-100">
                                                <div class="row w-100 m-0">
                                                    <div class="col-12 p-0">
                                                        <h3 class="card-title" *ngIf="plan.plan_id=='BP'"> pay once
                                                        </h3>
                                                        <h3 class="card-title" *ngIf="plan.plan_id=='FNP'"> every 15
                                                            days </h3>
                                                        <h3 class="card-title"
                                                            *ngIf="plan.plan_id=='PBYF'||plan.plan_id=='FNPL'"> from
                                                        </h3>
                                                        <span class="badge badge-warning p-2"
                                                            *ngIf="plan.plan_id=='BP'||plan.plan_id=='FNP'">Interest
                                                            free </span>
                                                        <div class="card-text">
                                                            <p><span>&#8377;{{ plan.emi_amount.toLocaleString('en-IN') }}</span></p>
                                                            <p class="font-weight-bold" *ngIf="plan.plan_id=='BP'">Pay
                                                                in one instalment</p>
                                                            <p class="font-weight-bold" *ngIf="plan.plan_id=='FNP'">Pay
                                                                in 2 to 6 instalments</p>
                                                            <p class="font-weight-bold" *ngIf="plan.plan_id=='PBYF'">Pay
                                                                before you travel</p>
                                                            <p class="font-weight-bold" *ngIf="plan.plan_id=='FNPL'">Pay
                                                                even after you travel</p>
                                                            <p class="small" *ngIf="plan.plan_id=='BP'">Pay upto 45 days
                                                                from booking</p>
                                                            <p class="small" *ngIf="plan.plan_id=='FNP'">Spread the cost
                                                                over 3 months</p>
                                                            <p class="small"
                                                                *ngIf="plan.plan_id=='PBYF'||plan.plan_id=='FNPL'">
                                                                Spread the cost over {{plan.tenor}} months</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </button>
                    </div>
                </div>
                <div class="row justify-content-center text-center buttonDiv mt-2">
                    <div class="col-sm-3 col-md-3 col-8">
                        <button type="submit" class="btn btn-warning btn-block" (click)="submit()" [disabled] = "selectedPlan === '' || selectedPlan === 'undefined'">Continue</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="hasLimitExhausted">
    <div class="row justify-content-center mt-3 d-flex flex-lg-row-reverse">
        <div class="col-lg-6 col-12" style="align-self: center;">
            <div class="row justify-content-center">
                <div class="col-8 blue_box p-4 text-center">
                    <h3 class="heading">Sorry!</h3>
                </div>
            </div>
            <div class="row justify-content-center mt-4">
                <div class="col col-lg-11 col-md-11">
                    <p style="text-align: center;">You have exhausted your limit</p>
                    <div style="text-align: center;">
                        <img src="../../../../assets/images/creditLimit.png" alt="credit Limit" style="height: 5rem;">
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-5 col-11">
            <app-assist-left></app-assist-left>
        </div>
    </div>
</div>
<ngx-spinner type="ball-spin-clockwise" size="medium"></ngx-spinner>