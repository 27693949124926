<div class="container-fluid applicationFormDiv">
    <div class="row justify-content-center">
        <div class="col-lg-8 col-md-11 col-sm-11 col-11">
            <div class="row mt-5 mb-5 d-flex shadow-sm subPagesDiv">
                <!-- <div class="col-12 col-lg-8 col-md-8 col-sm-12 leftSection"> -->
                <div class="col-12 leftSection">
                    <div class="row h-100">
                        <div class="col-sm-12 my-auto">
                            <h1>Sorry</h1> <img alt="Book Now Pay Later | Travel on EMI |Education loan | Mihuru" src="../../../assets/images/app-sorry.png" />
                            <p>We’re sorry we couldn’t approve your loan application right now.</p>
                        </div>
                     </div>
                </div>
                <!-- <div class="col-12 col-lg-4 col-md-4 col-sm-12 rightSection">
                    <div class="row justify-content-center h-100 notifyDiv">
                        <div class="col-lg-11 col-10 buttonDiv my-auto">
                            <h2>Try again in 3 months?</h2>
                            <p class="refTXt">You can always re-apply once 3 months are complete. Just login with your
                                contact
                                details to update your profile details and try again.</p>
                            <- <div class="row justify-content-center">
                                <div class="col-lg-11 col-10 col-md-11">
                                    <button type="submit" (click)="notifyMe()" class="btn btn-warning btn-block">Notify me after 3
                                        months</button>
                                </div>
                            </div> ->
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</div>
