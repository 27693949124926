import { Directive, ElementRef, Renderer2, AfterViewInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Directive({
  selector: 'img'
})
export class CdnImageDirective implements AfterViewInit {

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngAfterViewInit() {
    const imgElement: HTMLImageElement = this.el.nativeElement;
    const currentSrc = imgElement.getAttribute('src');
    if (currentSrc && currentSrc.includes('../assets')) {
      const removeTill = currentSrc.indexOf('/assets');
      const newSrc = currentSrc.replace(currentSrc.substring(0, removeTill), environment.CDN_BaseURL);
      this.renderer.setAttribute(imgElement, 'src', newSrc);
    }
  }
}
