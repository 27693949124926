import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AccountService } from 'src/app/core/services/account.service';
import { MemoryStorageService } from 'src/app/core/services/memory-storage.service';
import { SessionTimerService } from '../../../core/services/session-timer.service';

@Component({
  selector: 'app-session-expired',
  templateUrl: './session-expired.component.html',
  styleUrls: ['./session-expired.component.scss']
})
export class SessionExpiredComponent implements OnInit {

  constructor(private accountService: AccountService, private sessionTimerService :SessionTimerService, private spinner: NgxSpinnerService, private router: Router) { }

  ngOnInit(): void {
    this.spinner.hide();
    //this.sessionTimerService.resumeTimer();
    let obj = { statusReason: 'Session timed out' };
    this.accountService
      .setApplStatusReasonHistories(obj)
      .subscribe((res) => {
      // handle response
    });

    // Redirect to Partner Callback URL
    let url = localStorage.getItem('partnercallback');
    if(url && url !== 'undefined' && url.length > 1){
      window.location.replace(url);
      return;
    }

    // Clear Storage
    MemoryStorageService.clear();

  }
}
