<div class="container-fluid applicationFormDiv">
    <div class="row justify-content-center">
        <div class="col-lg-8 col-md-11 col-sm-11 col-11">
            <div class="row mt-5 mb-5 d-flex shadow-sm subPagesDiv">
                <div class="col-12 col-lg-8 col-md-8 col-sm-12 leftSection">
                    <div class="row h-100">
                        <div class="col-sm-12 my-auto">
                            <h1>Process offline</h1> <img alt="Book Now Pay Later | Travel on EMI |Education loan | Mihuru" src="../../../assets/images/app-sorry.png" />
                            <p>We’re sorry we couldn’t process your loan application right now. Your application will be processed by our credit team offline.</p>
                        </div>
                     </div>
                </div>
            </div>
        </div>
    </div>
</div>
