<div class="row justify-content-center m-0 flightTicketBox">
    <div class="col-lg-12 p-0 ticketBoxDiv">
        <div class="pb-0 payment-plan">
            <div *ngIf="!hasLimitExhausted">
                <div class="row justify-content-center">
                    <div class="col-12 col-md-8 text-center">
                        <h3>Congratulations! </h3>
                        <p>Congratulations you are approved, please select the payment plan of your choice to
                            continue booking your travel</p>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <!-- 01 -->
                    <div *ngFor="let plan of plans;" class="col-12 col-md-6 col-lg-3 mb-4">
                        <button class="image-flip h-100 w-100 btn" (click)="SelectPlan(plan)" [disabled]="!plan.plan_eligibility" style="border-style: none;">
                            <div class="mainflip flip-0 h-100">
                                <div class="frontside">
                                    <div class="card h-100" [class.selected-plan]="plan && selectedPlan && plan.plan_id === selectedPlan.plan_id && plan.tenor === selectedPlan.tenor">
                                        <div class="card-body align-items-center">
                                            <div class="d-flex h-100">
                                                <div class="row w-100 m-0">
                                                    <div class="col-12 p-0">


                                                        <h3 class="card-title" *ngIf="plan.plan_id=='BP'"> pay once
                                                        </h3>
                                                        <h3 class="card-title" *ngIf="plan.plan_id=='FNP'"> every 15
                                                            days </h3>
                                                        <h3 class="card-title"
                                                            *ngIf="plan.plan_id=='PBYF'||plan.plan_id=='FNPL'"> from
                                                        </h3>
                                                        <span class="badge badge-warning p-2"
                                                            *ngIf="plan.plan_id=='BP'||plan.plan_id=='FNP'">Interest
                                                            free </span>
                                                        <div class="card-text">
                                                            <p><span>&#8377;{{ plan.emi_amount.toLocaleString('en-IN') }}</span></p>
                                                            <p class="font-weight-bold" *ngIf="plan.plan_id=='BP'">Pay
                                                                in one instalment</p>
                                                            <p class="font-weight-bold" *ngIf="plan.plan_id=='FNP'">Pay
                                                                in 2 to 6 instalments</p>
                                                            <p class="font-weight-bold" *ngIf="plan.plan_id=='PBYF'">Pay
                                                                before you travel</p>
                                                            <p class="font-weight-bold" *ngIf="plan.plan_id=='FNPL'">Pay
                                                                even after you travel</p>
                                                            <p class="small" *ngIf="plan.plan_id=='BP'">Pay upto 45 days
                                                                from booking</p>
                                                            <p class="small" *ngIf="plan.plan_id=='FNP'">Spread the cost
                                                                over 3 months</p>
                                                            <p class="small"
                                                                *ngIf="plan.plan_id=='PBYF'||plan.plan_id=='FNPL'">
                                                                Spread the cost over {{plan.tenor}} months</p>
                                                        </div>


                                                        <!-- <span class="badge badge-warning p-2" *ngIf="plan.plan_id=='BP'||plan.plan_id=='FNP'">Interest free</span>
                                                        <h3 class="card-title">{{GenerateTitle(plan.plan_id!)}}</h3>
                                                        <div class="card-text">
                                                            <p><span> &#8377; {{plan.emi_amount}}</span></p>
                                                            <p class="font-weight-bold">
                                                                {{GeneratePayIn(plan.plan_id!)}}</p>
                                                            <p class="small">{{GeneratePayUpto(plan.plan_id!, , plan.tenor)}}</p>
                                                        </div> -->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </button>
                    </div>
                </div>
                <!-- 01 -->
                <div class="row justify-content-center" *ngIf="selectedPlan">
                    <div class="col-sm-7 col-11 col-lg-6">
                        <div class="backside">
                            <div class="card">
                                <div class="card-body text-center pt-0 pb-0">
                                    <div class="justify-content-center">
                                        <div class="row justify-content-center">
                                            <div class="col-5">
                                                <h4 class="card-title mt-3"> Pay now<br>
                                                    <span class="amount">&#8377; {{GeneratePayNow(selectedPlan)}}</span>
                                                </h4>
                                            </div>
                                            <div class="col-7">
                                                <h4 class="card-title mt-3"> EMI <br><span class="amount"> &#8377;
                                                        {{selectedPlan.emi_amount.toLocaleString('en-IN')}}</span> <small> x
                                                        {{selectedPlan.tenor}}</small></h4>
                                                <p class="note font-weight-bold">Due every 5<sup>th</sup> of month
                                                </p>
                                            </div>
                                        </div>
                                        <div class="row justify-content-center">
                                            <div class="col-12 p-0">
                                                <table class="table">
                                                    <tbody>
                                                        <!-- <tr>
                                                            <td width="70%">Trip price </td>
                                                            <td class="text-right">
                                                                &#8377;{{selectedPlan.package_cost}}</td>
                                                        </tr> -->
                                                        <tr>
                                                            <td width="70%">Processing fee including GST (pay today)</td>
                                                            <td class="text-right"> &#8377; {{GenerateProcessingFee(selectedPlan)}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td width="70%">Downpayment (pay today)</td>
                                                            <td class="text-right"> &#8377;
                                                                {{selectedPlan.downpayment.toLocaleString('en-IN')}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td width="70%"><strong>Pay Today</strong></td>
                                                            <td class="text-right"><strong> &#8377; {{GeneratePayNow(selectedPlan)}}</strong></td>
                                                        </tr>
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <td width="70%">Loan amount</td>
                                                            <td class="text-right"> &#8377;
                                                                {{selectedPlan.loan_amount.toLocaleString('en-IN')}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td width="70%">Interest</td>
                                                            <td class="text-right"> &#8377; {{GenerateInterest(selectedPlan)}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td width="70%"><strong>Total payable</strong></td>
                                                            <td class="text-right"><strong> &#8377; {{GenerateTotalPayable(selectedPlan)}}</strong></td>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center text-center buttonDiv mt-2" *ngIf="isPlanSelected">
                    <div class="col-lg-3 col-sm-3 col-md-6 col-8">
                        <button type="submit" class="btn btn-warning btn-block" (click)="ProceedToNach()">Continue</button>
                    </div>
                </div>
            </div>
        </div>     
    </div>
</div>

<div *ngIf="hasLimitExhausted">
    <div class="row justify-content-center mt-3 d-flex flex-lg-row-reverse">
        <div class="col-lg-6 col-12" style="align-self: center;">
            <div class="row justify-content-center">
                <div class="col-8 blue_box p-4 text-center">
                    <h3 class="heading">Sorry!</h3>
                </div>
            </div>
            <div class="row justify-content-center mt-4">
                <div class="col col-lg-11 col-md-11">
                    <p style="text-align: center;">You have exhausted your limit</p>
                    <div style="text-align: center;">
                        <img src="../../../../assets/images/creditLimit.png" alt="credit Limit" style="height: 5rem;">
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-5 col-11">
            <app-assist-left></app-assist-left>
        </div>
    </div>
</div>
<ngx-spinner type="ball-spin-clockwise" size="medium"></ngx-spinner>