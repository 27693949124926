<div class="row justify-content-center mt-3 d-flex flex-lg-row-reverse">
    <div class="col-lg-6 col-12">
        <div class="row justify-content-center mt-4">
            <div class="col-8 blue_box p-4 text-center">
                <h3 class="heading">Apply Now</h3>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-11 white_box">
                <form class="container-fluid page mt-60" ngForm #form="ngForm" (ngSubmit)="submit()" autocomplete="off">
                    <div id="main">
                        <div class="accordion mb-4 mt-4" id="accordionExample">
                            <div class="card">
                                <div class="accordion-title" id="headingOne">
                                    <h2 class="mb-0">
                                        <button class="btn btn-link btn-block text-left" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true"
                                            aria-controls="collapseOne">
                                            Signup
                                        </button>
                                    </h2>
                                </div>
                                <div id="collapseOne" class="collapse show" aria-labelledby="headingOne"
                                    data-bs-parent="#accordionExample">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-12 col-xl-6">
                                                <div class="form-group">
                                                    <label for="Mobilenumber" class="control-label">Mobile</label>
                                                    <input type="tel" class="form-control"
                                                        placeholder="Your mobile number" [(ngModel)]="signUp.mobileNo"
                                                        required [pattern]="mobileRegexPattern" #mobileNo="ngModel"
                                                        minlength="10" maxlength="10" name="mobileNo"
                                                        (ngModelChange)="onInputChange($event)" 
                                                        (input)="allowOnly($event,'numbers')">
                                                    <div class="error mt-1" *ngIf="shouldShowValidation(mobileNo)">
                                                        <div>Please enter a valid mobile number.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-xl-6">
                                                <div class="form-group">
                                                    <label for="Emailaddress" class="control-label">Email</label>
                                                    <input type="email" class="form-control" email
                                                        placeholder="Your email address" [(ngModel)]="signUp.email"
                                                        required #email="ngModel" name="email"
                                                        pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$">
                                                    <div class="error mt-1" *ngIf="shouldShowValidation(email)">
                                                        <div>Please enter a valid email.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <small>We will send you OTP on mobile</small>
                                        <div class="row justify-content-center text-center buttonDiv">
                                            <div class="col-12">
                                                <button type="button" class="btn btn-warning mt-3"
                                                    (click)="GetSignInData(!(form.controls?.email?.invalid || form.controls?.mobileNo?.invalid))"
                                                    [disabled]="form.controls?.email?.invalid || form.controls?.mobileNo?.invalid">
                                                    Next </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card" *ngIf="showPersonalDetails">
                                <div class="accordion-title" id="headingTwo">
                                    <h2 class="mb-0">
                                        <button class="btn btn-link btn-block text-left collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false"
                                            aria-controls="collapseTwo">
                                            Personal details
                                        </button>
                                    </h2>
                                </div>
                                <div id="collapseTwo" #collapseTwo class="collapse" aria-labelledby="headingTwo"
                                    data-bs-parent="#accordionExample">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-12 col-xl-6">
                                                <div class="form-group">
                                                    <label for="firstname " class="control-label">First name
                                                    </label>
                                                    <input type="text" class="form-control"
                                                        placeholder="First name as on PAN card"
                                                        [(ngModel)]="signUp.firstName" required pattern="[a-zA-Z\s']{2,50}"
                                                        #firstName="ngModel" minlength="2" maxlength="50"
                                                        name="firstName" (input)="allowOnly($event,'alphabets')" (input)="toUpperCase($event)"
                                                        [disabled]='disableAll'>
                                                    <div class="error mt-1" *ngIf="shouldShowValidation(firstName)">
                                                        <div>Please enter a valid first name.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-xl-6">
                                                <div class="form-group">
                                                    <label for="Lastname " class="control-label">Middle name & Last name </label>
                                                    <input type="text" class="form-control"
                                                        placeholder="Last name as on PAN card"
                                                        [(ngModel)]="signUp.lastName" required pattern="[a-zA-Z\s']{1,50}"
                                                        #lastName="ngModel" minlength="1" maxlength="50"
                                                        name="lastName" (input)="allowOnly($event,'alphabets')" (input)="toUpperCase($event)" 
                                                        [disabled]='disableAll'>
                                                    <div class="error mt-1" *ngIf="shouldShowValidation(lastName)">
                                                        <div>Please enter a valid last name.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-xl-6">
                                                <div class="form-group">
                                                    <label for="MonthlyIncome" class="control-label">Monthly income
                                                        &#8377;
                                                    </label>
                                                    <input type="number" class="form-control"
                                                        placeholder="Your monthly income"
                                                        [(ngModel)]="signUp.monthlyIncome" required
                                                        #monthlyIncome="ngModel" name="monthlyIncome"(focus)="clearInitialValue($event)" (input)="validateMonthlyincome($event)"(input)="allowOnly($event,'numbers')"
                                                        [disabled]="disableIncomeDetail && disableAll">
                                                        <div>
                                                        <div *ngIf="isMonthlyError && monthlyErroMsg" class="error mt-1">
                                                            {{ monthlyErroMsg }}
                                                        </div>
                                                        </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row justify-content-center text-center buttonDiv">
                                            <div class="col-12">
                                                <button type="button" class="btn btn-warning mt-3"
                                                    (click)="showTravelDetailsFun(!(form.controls?.firstName?.invalid || form.controls?.lastName?.invalid))"
                                                    [disabled]="form.controls?.firstName?.invalid || form.controls?.lastName?.invalid || isMonthlyError">
                                                    Next </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card" *ngIf="showTravelDetails">
                                <div class="accordion-title" id="headingThree">
                                    <h2 class="mb-0">
                                        <button class="btn btn-link btn-block text-left collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false"
                                            aria-controls="collapseThree">
                                            Travel details
                                        </button>
                                    </h2>
                                </div>
                                <div id="collapseThree" #collapseThree class="collapse" aria-labelledby="headingThree"
                                    data-bs-parent="#accordionExample">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-12 col-xl-6">
                                                <div class="form-group">
                                                    <label for="Traveltype " class="control-label">Travel type
                                                    </label>
                                                    <select class="form-select" id="travelType"
                                                        [(ngModel)]="signUp.travelType" #travelType="ngModel"
                                                        name="travelType" 
                                                        [disabled]='disableTravelDetail && disableAll' required>
                                                        <option disabled [ngValue]="null">Choose type of travel</option>
                                                        <option [ngValue]="'Flight'">Flight</option>
                                                        <option [ngValue]="'Hotel'">Hotel</option>
                                                        <option [ngValue]="'Holiday Package'">Holiday Package</option>
                                                    </select>
                                                    <div *ngIf="travelType?.invalid && (travelType.dirty || travelType.touched)"
                                                        class="error mt-1">
                                                        <div *ngIf="travelType.errors?.required">Travel type is
                                                            required.</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-xl-6">
                                                <div class="form-group">
                                                    <label for="traveldate" class="control-label">Travel
                                                        date</label>
                                                    <input type="date" class="form-control" placeholder="Travel date"
                                                        [(ngModel)]="signUp.travelDate" required #travelDate="ngModel"
                                                        name="travelDate" [max]="maxDate" [min]="minDate" (input)="validateTravelDate()">
                                                    <div *ngIf="travelDate?.invalid && (travelDate.dirty || travelDate.touched)"
                                                        class="error mt-1">
                                                        <div *ngIf="travelDate.errors?.required">Travel date is
                                                            required.</div>
                                                        <div *ngIf="travelDate.errors?.min">Travel date cannot be
                                                            before {{ minDate }}.</div>
                                                        <div *ngIf="travelDate.errors?.max">Travel date cannot be
                                                            after {{ maxDate }}.</div>
                                                        <div *ngIf="travelDate.errors?.max">Travel date cannot be
                                                            before {{ minDate }}.</div>
                                                    </div>
                                                    <div class="error mt-1" *ngIf="travelDateValidate">
                                                    <div>Travel date cannot be
                                                        before {{ minDate }}.</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-xl-6">
                                                <div class="form-group">
                                                    <label for="travelCost" class="control-label">Invoice Amount &#8377;</label>
                                                    <input type="number" class="form-control"
                                                           placeholder="Your trip cost"
                                                           [(ngModel)]="signUp.travelCost"
                                                           required
                                                           #tripCost="ngModel"
                                                           name="travelCost"
                                                           (focus)="clearInitialValue($event)"
                                                           (input)="clearInitialValue($event)"
                                                           (input)="allowOnly($event, 'numbers')" (change)="validateTravelCost()"
                                                           [disabled]='disableTravelDetail && disableAll' >
                                                         <div *ngIf="isError" class="error mt-1">
                                                            {{ errorMsg }}
                                                        </div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-xl-6">
                                                <div class="form-group">
                                                    <label for="origin" class="control-label">Origin</label>
                                                    <input type="text" class="form-control" placeholder="From city"
                                                        [(ngModel)]="signUp.origin" required pattern="[a-zA-Z\s]{2,50}"
                                                        #origin="ngModel" minlength="2" maxlength="50" name="origin" (input)="allowOnly($event,'alphabets')" (input)="toUpperCase($event)" 
                                                        [disabled]='disableTravelDetail && disableAll' >
                                                    <div *ngIf="origin?.invalid && (origin.dirty || origin.touched)"
                                                        class="error mt-1">
                                                        <div *ngIf="origin.errors?.required">Origin city is
                                                            required.</div>
                                                        <div *ngIf="origin.errors?.pattern">Origin city must contain
                                                            only letters.</div>
                                                        <div *ngIf="origin.errors?.minlength">Origin city must be at
                                                            least 2 characters long.</div>
                                                        <div *ngIf="origin.errors?.maxlength">Origin city cannot be
                                                            more than 50 characters long.</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-xl-6">
                                                <div class="form-group">
                                                    <label for="destination" class="control-label">Destination
                                                    </label>
                                                    <input type="text" class="form-control" placeholder="To city"
                                                        [(ngModel)]="signUp.destination" required pattern="[a-zA-Z\s]{2,50}"
                                                        #destination="ngModel" minlength="2" maxlength="50"
                                                        name="destination" (input)="allowOnly($event,'alphabets')" (input)="toUpperCase($event)" 
                                                        [disabled]='disableTravelDetail && disableAll' >
                                                    <div *ngIf="destination?.invalid && (destination.dirty || destination.touched)"
                                                        class="error mt-1">
                                                        <div *ngIf="destination.errors?.required">Destination city
                                                            is required.</div>
                                                        <div *ngIf="destination.errors?.pattern">Destination city
                                                            must contain only letters.</div>
                                                        <div *ngIf="destination.errors?.minlength">Destination city
                                                            must be at least 2 characters long.</div>
                                                        <div *ngIf="destination.errors?.maxlength">Destination city
                                                            cannot be more than 50 characters long.</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row justify-content-center text-center buttonDiv">
                                            <div class="col-12">
                                                <button type="submit" class="btn btn-warning mt-3"
                                                    [disabled]="(signUp.travelCost < 3000 || signUp.travelCost > 1000000 ) || isMonthlyError || form.controls?.firstName?.invalid || form.controls?.lastName?.invalid||form.controls?.email?.invalid || form.controls?.mobileNo?.invalid||form.controls?.origin?.invalid || form.controls?.destination?.invalid||form.controls?.travelType?.invalid || form.controls?.travelDate?.invalid || travelDateValidate">
                                                    Next </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </form>
            </div>
        </div>
        <p class="text-center mb-4">
            <small>
                <span class="font-weight-bold">Note -</span> Credit eligibility checks do not affect your credit
                score.
                <!-- <a href="#myContent">Know more</a> -->
            </small>
        </p>
    </div>
    <div class="col-lg-5 col-11">
        <app-assist-left></app-assist-left>
    </div>
    <ngx-spinner type="ball-spin-clockwise" size="medium"></ngx-spinner>
</div>