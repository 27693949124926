import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { firstValueFrom } from 'rxjs';
import { MemoryStorageService } from 'src/app/core/services/memory-storage.service';
import { environment } from 'src/environments/environment';
import swal from 'sweetalert2';
import { Plan } from '../../../core/models/personalInfo';
import { AccountService } from '../../../core/services/account.service';
import { SessionTimerService } from '../../../core/services/session-timer.service';

@Component({
  selector: 'app-assist-payment-plan',
  templateUrl: './assist-payment-plan.component.html',
  styleUrls: ['./assist-payment-plan.component.scss'],
})
export class AssistPaymentPlanComponent implements OnInit {
  public href: string = '';
  public travelPlan: string = '';
  FirstName: string = '';
  Mobile: string = '';
  Email: string = '';
  PAN: string = '';
  DOB: string = '';
  Address: string = '';
  DocsRequired: string = '';
  Downpayment: string = '';
  EmiAmount: string = '';
  Loanamount: string = '';
  FirstEMIDueDate: string = '';
  NumberOfTotalEMI: string = '';
  travelPlanId : string = '';

  constructor(
    private route: ActivatedRoute,
    private accountService: AccountService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private sessionTimerService :SessionTimerService,
  ) {}

  plans!: Plan[];
  inPrincipleAmount!: number;
  response1!: any;
  isPlanSelected : boolean = false;
  hasLimitExhausted: boolean = false;

  async ngOnInit() {

    this.spinner.hide();
    var user = JSON.parse(MemoryStorageService.getItem('user')!);
    this.inPrincipleAmount = +MemoryStorageService.getItem('availableLimit');

    // for testing only
    // if(MemoryStorageService.getItem("mobile") === "9990022401"){
    //   this.plans = this.testDataMapper(MemoryStorageService.getItem("assistTravelPlans"));
    //   return;
    // }
    try{
      this.spinner.show();
      const response = await firstValueFrom(this.accountService.GetProposedPlans());
      this.plans = response;
      this.spinner.hide();
    }catch(err){

    }
    //not checking now
    //this.hasLimitExhausted = MemoryStorageService.getItem("hasLimitExhausted") === 'true';
    if(this.hasLimitExhausted)
    { 
      this.sessionTimerService.SetShowTimer(false);
      this.sessionTimerService.stopTimer();

      //Not showing Exhause message and redirecting to manual Underwritting 
      this.hasLimitExhausted = false;
      this.router.navigateByUrl('/assist-journey/assist-underwrite');
      return
    }
    else{
      //this.sessionTimerService.resumeTimer();
    }
    // this.sessionTimerService.SetShowTimer(true);
    // this.sessionTimerService.resumeTimer();
    this.checkPlanEligibility(this.plans);
  }
  selectedPlan: Plan;
  SelectPlan(plan: Plan) {
    this.selectedPlan = plan;
    this.isPlanSelected = true;
  }

  conditionAccept: boolean = false;
  async ProceedToNach() {
    //save to db
    //save to MemoryStorageService
    this.spinner.show();
    MemoryStorageService.setItem('finalplan', JSON.stringify(this.selectedPlan));
    var finalPlan = JSON.parse(MemoryStorageService.getItem('finalplan'));
    this.travelPlanId = new URL(finalPlan.navigate_url).search.replace('?travelplan=','');
    await this.accountService.GetTravelPlan(this.travelPlanId).subscribe(
      (resp) => {
        this.spinner.hide();
        this.response1 = resp;
        const response = resp['data'];  
        console.log('GetTravelPlan');
        console.log(response);
        if (response != null) {
          if (response.status == "EXPIRED") {
            swal.fire('The Plan is expired. Please try again');
            this.router.navigateByUrl("/sorry");

          } else if (response.status == "INVALID") {
            swal.fire('The selected plan is not valid anymore!');
            this.router.navigateByUrl("/sorry");
          }
          else {
            environment.production ? this.router.navigateByUrl('/assist-journey/team-callback') : this.router.navigateByUrl('/assist-journey/loan-agreement');
          }
        }
      },
      (error) => {
        this.spinner.hide();
        if (error.errors != null || error.errors != undefined) {
          swal.fire(error.errors);
        }
      }
    );
  }

  GenerateTitle(value: string) {
    if (value == 'PBYF' || value == 'FNPL') {
      return 'from';
    } else if (value == 'FNP') {
      return 'pay every 15 days';
    } else if (value == 'BP') {
      return 'pay once';
    } else {
      return value;
    }
  }

  GeneratePayIn(value: string) {
    if (value == 'PBYF') {
      return 'Pay before your travel';
    } else if (value == 'FNPL') {
      return 'pay even after your travel';
    } else if (value == 'FNP') {
      return 'Pay in 2 to 6 instalments';
    } else if (value == 'BP') {
      return 'Pay in one instalment';
    } else {
      return value;
    }
  }

  GeneratePayUpto(value: string, tenure: string) {
    if (value == 'PBYF' || value == 'FNPL') {
      return 'Spread the cost over '+tenure+' months';
    } else if (value == 'FNP') {
      return 'Spread the cost over 3 months';
    } else if (value == 'BP') {
      return 'Pay upto 45 days from booking';
    } else {
      return value;
    }
  }

  GenerateProcessingFee(plan: Plan) {
    return (plan.processing_fee! + plan.gst!).toLocaleString('en-IN');
  }
  GeneratePayNow(plan: Plan) {
    return (plan.processing_fee! + plan.gst! + plan.downpayment!).toLocaleString('en-IN');
  }
  GenerateInterest(plan: Plan) {
    return ((plan.emi_amount! * plan.tenor!)-(plan.loan_amount!)).toLocaleString('en-IN');
  }
  GenerateTotalPayable(plan: Plan) {
    return (plan.emi_amount! * plan.tenor!).toLocaleString('en-IN');
  }

  checkPlanEligibility(plans: any[]) {
    const hasEligiblePlan = plans.some(plan => plan.plan_eligibility);

    if (!hasEligiblePlan) {
      return this.router.navigateByUrl('/assist-journey/assist-underwrite');
    }
  }

  testDataMapper(jsonData){
    const plans: Plan[] = JSON.parse(jsonData).map((item: any) => ({
      plan_id: item.plan_id,
      plan_name: item.plan_name,
      plan_eligibility: item.plan_eligibility,
      package_cost: item.package_cost,
      loan_amount: item.loan_amount,
      downpayment: item.downpayment,
      processing_fee: item.processing_fee,
      upfrontAmountPayDate: item.upfrontAmountPayDate,
      emi_amount: item.emi_amount,
      emi_date: new Date(item.emi_date), 
      tenor: item.tenor,
      roi: item.roi,
      gst: item.gst,
      nach_frequency: item.nach_frequency,
      status: item.status,
      message: item.message,
      emiPlan: item.emiPlan,
      navigate_url: item.navigate_url,
      qr_code: item.qr_code,
    }));
    return plans;
  }
}
